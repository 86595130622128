<template>
  <div class="ml2">

    <div class="ml0">
      <style-edit-boolean
        label="Enabled"
        v-model="localLabel.Enabled"
        @value-changed="updateStyle"
      />
      <div>
        <style-edit-field-label label="Font Size" />
        <input
          v-model.number="localLabel.FontSize"
          @change="updateStyle"
          class="w3 f5"
        />
      </div>
      <div>
        <style-edit-field-label label="Offset Scale" />
        <input
          v-model.number="localLabel.OffsetScale"
          @change="updateStyle"
          class="w3 f5"
        />
      </div>
      <div>
        <style-edit-field-label label="Offset Angle (Degrees)" class="" />
        <input
          v-model.number="localLabel.OffsetAngleD"
          @change="updateStyle"
          class="w3 f5"
        />
      </div>
    </div>
    <span class="f5"><pre>{{ localLabel }}</pre></span>
  </div>
</template>

<script>
import StyleEditFieldLabel from '@/components/StyleEditor/StyleEditFieldLabel.vue';
import StyleEditBoolean from '@/components/StyleEditor/StyleEditBoolean.vue';
export default {
  name: 'StyleEditLabel',
  props: {
    label: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localLabel: this.label,
    };
  },
  watch: {
    localLabel: function(newVal, oldVal) {
      console.log('Edited localLabel (new, old): ', newVal, oldVal);
    },
  },
  created() {
  },
  methods: {
    updateStyle() {
      console.log('StyleEditTransform, valueChange: ', this.localLabel);
      this.$emit('value-changed', this.localLabel);
    },
  },
  components: {
    StyleEditFieldLabel,
    StyleEditBoolean,
  },
};
</script>
