<template>
  <div>
    <a :href="qrUrlDefaultScheme">
      <vue-qr
        :bindElement="'false'"
        :dotScale="'0.8'"
        :text="qrUrlHttpsScheme"
        :size="200"
      ></vue-qr>
    </a>
  </div>
</template>

<script>
// @ is an alias to /src
import Helpers from "@/helpers.js";
import VueQr from "vue-qr";

export default {
  name: "Template",
  props: {
    url: String,
    username: String,
    password: String,
    projectID: String,
  },
  computed: {
    qrUrlDefaultScheme: function () {
      return (
        "edxt://app.edxt.io/launch" +
        "?username=" +
        this.username +
        "&password=" +
        Helpers.base64Encode(this.password) +
        "&url=" +
        this.url +
        "&projectID=" +
        this.projectID
      );
    },
    qrUrlHttpsScheme: function () {
      return (
        "https://www.edxtech.com/launch" +
        "?username=" +
        this.username +
        "&password=" +
        Helpers.base64Encode(this.password) +
        "&url=" +
        this.url +
        "&projectID=" +
        this.projectID
      );
    },
  },
  data() {
    return {};
  },
  methods: {
    eventHandler(msg) {
      Helpers.showAlert("Alert triggered with msg: " + msg);
    },
  },
  created: function () {
    // Call our base-class-like setup function
    Helpers.doViewCreation(this);
  },
  mounted: function () {},
  components: { VueQr },
};
</script>
