<template>
  <div>
    <vue-context ref="menu" @open="notifyContextMenuOpened">
        <li>
            <a href="#" @click.prevent="deleteMe($event.target.innerText)">Delete {{nodeLabel}}</a>
        </li>
        <li>
            <a href="#" @click.prevent="insertAboveMe($event.target.innerText)">Insert Style Above {{nodeLabel}}</a>
        </li>
        <li>
            <a href="#" @click.prevent="insertChildToMe($event.target.innerText)">Add Child Style to {{nodeLabel}}</a>
        </li>

        <!-- <li>
            <a href="#" @click.prevent="onRtClick($event.target.innerText)">Insert Above</a>
        </li>
        <li>
            <a href="#" @click.prevent="onRtClick($event.target.innerText)">Insert Below</a>
        </li> -->

    </vue-context>

    <span v-if="treeLevel == 0 || expandNodes" :style="indentLevel" @contextmenu.prevent="$refs.menu.open">
      <span v-if="hasChildren && !showChildren" @click="toggleExpansion">
        <slot name="iconParent">
          +
        </slot>
      </span>

      <span v-else-if="hasChildren && showChildren" @click="toggleExpansion">
        <slot name="iconParentExpanded">
          -
        </slot>
      </span>

      <span v-else>
        <slot name="iconChild">
          >
        </slot>
      </span>

      <span
        @dblclick="toggleExpansion"
        @click="handleNodeClick(node)"
        :class="{ selectedColor: selected }"
      >
        {{ nodeLabel }}
        <!-- <span v-if="hasChildren">
          ({{ node[childrenDataProperty].length }})
        </span> -->
      </span>
      <span v-if="selected" style="float: right">
        <span @click="shiftNodeUp">
          <slot name="iconShiftNodeUp">
            ^
          </slot>
        </span>

        <span @click="shiftNodeDown">
          <slot name="iconShiftNodeDown">
            V
          </slot>
        </span>
      </span>
      <!-- <span>{{ expandNodes }} | {{ showChildren }}</span> -->
    </span>

    <span v-if="showChildren">
      <tree-list-ctrl-node
        v-for="(child, idx) in node[childrenDataProperty]"
        :key="child[uniqueIDDataProperty] ? child[uniqueIDDataProperty] : idx"
        :index="idx"
        :node="child"
        :parentNode="node"
        :labelDataProperty="labelDataProperty"
        :secondLabelDataProperty="secondLabelDataProperty"
        :childrenDataProperty="childrenDataProperty"
        :handleNodeClick="handleNodeClick"
        :handleBeforeDelete="handleBeforeDelete"
        :handleBeforeInsert="handleBeforeInsert"
        :selectedNode="selectedNode"
        :treeLevel="treeLevel + 1"
        :indentForEachTreeLevel_Pixels="indentForEachTreeLevel_Pixels"
        :expandNodes="showChildren"
        :truncateLabelAtChars="truncateLabelAtChars"
        @tlctr-node-shift-up="shiftChildNodeUp"
        @tlctr-node-shift-down="shiftChildNodeDown"
        @tlctr-node-delete="deleteChildNode"
        @tlctr-node-insert-above="insertAboveChildNode"
        @tlctr-node-insert-child="insertChild"
        @tlctr-node-order-changed="$emit('tlctr-node-order-changed')"
        @tlctr-node-inserted="$emit('tlctr-node-inserted')"
        @tlctr-select-new-node="selectNewNode"
      >
        <template #iconParent>
          <slot name="iconParent">
            +
          </slot>
        </template>
        <template #iconParentExpanded>
          <slot name="iconParentExpanded">
            -
          </slot>
        </template>
        <template #iconChild>
          <slot name="iconChild">
            >
          </slot>
        </template>
        <template #iconShiftNodeUp>
          <slot name="iconShiftNodeUp">
          ^
          </slot>
        </template>
        <template #iconShiftNodeDown>
          <slot name="iconShiftNodeDown">
          V
          </slot>
        </template>
      </tree-list-ctrl-node>
    </span>
  </div>
</template>

<script>
// @ is an alias to /src
// import Helpers from "@/helpers.js";
// import ClickToEdit from '@/components/ClickToEdit.vue';
import Vue from 'vue';
import VueContext from 'vue-context';

import {treeListCtrlMessageBus} from '@/components/TreeListCtrl.vue';

export default {
  name: 'TreeListCtrlNode',
  props: {
    node: {
      type: Object,
      required: true,
    },
    parentNode: {
      type: Object,
      required: false,
      default: () => {
        return null; // return null instead of an empty obj as there is no parent by default
      },
    },
    labelDataProperty: {
      type: String,
      required: true,
    },
    secondLabelDataProperty: {
      type: String,
      required: false,
      default: null,
    },
    childrenDataProperty: {
      type: String,
      required: true,
    },
    uniqueIDDataProperty: {
      type: String,
      required: false,
      default: 'id',
    },
    handleNodeClick: {
      type: Function,
      required: true,
    },
    handleBeforeDelete: {
      type: Function,
      required: false,
    },
    handleBeforeInsert: {
      type: Function,
      required: false,
      default: null,
    },
    selectedNode: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    treeLevel: {
      type: Number,
      default: 0,
      required: false,
    },
    indentForEachTreeLevel_Pixels: {
      type: Number,
      default: 10,
      required: false,
    },
    expandNodes: {
      type: Boolean,
      default: false,
      required: false,
    },
    toggleNodeReset: {
      type: Boolean,
      default: false,
      required: false,
    },
    truncateLabelAtChars: {
      type: Number,
      default: 25,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
    // options: {
    //   type: Object,
    //   required: false,
    //   default: () => {
    //     return {
    //     };
    //   },
    // }, // end options
  },
  watch: {
    expandNodes: function(newVal, oldVal) {
      // watch it
      console.log('Prop changed (expandNodes): ', newVal, ' | was: ', oldVal);
      this.showChildren = newVal;
    },
    toggleNodeReset() {
      // don't care what it changed to... just reset object to defaults
      this.showChildren = this.expandNodes;
    },
    selectedNode(newVal) {
      if (this.node === newVal) {
        this.selected = true;
        console.log('Child Selected: ', this.node);
      } else {
        this.selected = false;
      }
    },
  },
  data() {
    return {
      showChildren: false,
      selected: false,
    };
  },
  computed: {
    indentLevel() {
      let i =
        'margin-left: ' +
        this.indentForEachTreeLevel_Pixels * this.treeLevel +
        'px;';
      return i;
    },
    hasChildren() {
      if (
        this.childrenDataProperty &&
        this.node[this.childrenDataProperty] &&
        this.node[this.childrenDataProperty].length > 0
      ) {
        return true;
      }
      return false;
    },
    secondLabelExists() {
      if (this.secondLabelDataProperty && this.secondLabelDataProperty != '') {
        if (
          this.secondLabelDataProperty &&
          this.node[this.secondLabelDataProperty] &&
          this.node[this.secondLabelDataProperty] != ''
        ) {
          return true;
        }
      }
      return false;
    },
    nodeLabel() {
      if (!this.node[this.labelDataProperty]) {
        return '!Empty Label!';
      }

      let label = this.node[this.labelDataProperty];

      if (this.node[this.secondLabelDataProperty]) {
        label += ' - ';
        label += this.node[this.secondLabelDataProperty];
      }

      return label.substring(0, this.truncateLabelAtChars);
    },
  },
  methods: {
    toggleExpansion() {
      if (this.hasChildren) {
        this.showChildren = !this.showChildren;
      }
    },
    insertChildToMe() {
      // fires for the node that is rt. clicked on
      console.log("insertChild", this.node);

      if(this.handleBeforeInsert) {
        this.handleBeforeInsert(this.node).then(ret => {
          if(ret) {
            console.log("Insert confirmation resolved to true");
            if(!this.node[this.childrenDataProperty]) {
              Vue.set(this.node, this.childrenDataProperty, []);
            }
            let childrenArray = this.deepCopy(this.node[this.childrenDataProperty]);
            childrenArray.splice(0,0,ret);
            Vue.set(this.node, this.childrenDataProperty, childrenArray);
            this.$emit('tlctr-node-insert-child', this.node, ret);
          } else {
            console.log("Insert confirmation resolved to FALSE");
          }
        }, err => {
          console.log("Insert confirmation resolved with ERROR", err);
        });
      } else {
        let obj = {};
        obj[this.labelDataProperty] = "Default"
        // TODO: just insert child and emit change
        console.log("insertChildToMe: ", this.node, obj);
        if(!this.node[this.childrenDataProperty]) {
          // this.node[this.childrenDataProperty] = [];
          Vue.set(this.node, this.childrenDataProperty, []);
        }
        let childrenArray = this.$parent.deepCopy(this.node[this.childrenDataProperty]);
        childrenArray.splice(0, 0, obj);
        Vue.set(this.node, this.childrenDataProperty, childrenArray);
        this.$emit('tlctr-node-insert-child', this.node, obj);
      }
    },
    insertChild(node, obj) {
      // fires for parent node of the object that was clicked on for inserting to
      console.log("insertChild: ", node);
      this.$emit('tlctr-node-insert-child', node, obj);
    },
    insertAboveMe() {
      // fires for the node that is rt. clicked on
      console.log("insertAboveMe", this.node);

      if(this.handleBeforeInsert) {
        this.handleBeforeInsert(this.parentNode).then(ret => {
          if(ret) {
            console.log("Insert confirmation resolved to true");
            this.$emit('tlctr-node-insert-above', this.index, this.node, ret); // ret is the object to isnert
          } else {
            console.log("Insert confirmation resolved to FALSE");
          }
        }, err => {
          console.log("Insert confirmation resolved with ERROR", err);
        });
      } else {
        let obj = {};
        obj[this.labelDataProperty] = "Default"
        this.$emit('tlctr-node-insert-above', this.index, this.node, obj);
      }
    },
    insertAboveChildNode(index, node, obj) {
      // fires for the parent node of the node that was rt. clicked on
      // Do the actual insertion work here
      if (obj && typeof obj === 'object' && obj.constructor === Object){
        // we have an object
        console.log("insertAboveChildNode: ", this.node, index, node);
        let childrenArray = this.deepCopy(this.node[this.childrenDataProperty]);
        childrenArray.splice(index, 0, obj);
        Vue.set(this.node, this.childrenDataProperty, childrenArray);
        this.$emit('tlctr-node-inserted');
      }
    },
    deleteMe(text) {
      console.log("(deleteMe) user rt clicked on: ", text);
      if(this.handleBeforeDelete) {
        this.handleBeforeDelete().then(ret => {
          if(ret) {
            console.log("Delete confirmation resolved to true");
            this.$emit('tlctr-node-delete', this.index, this.node);
          } else {
            console.log("Delete confirmation resolved to FALSE");
          }
        }, err => {
          console.log("Delete confirmation resolved with ERROR", err);
        });
      } else {
        this.$emit('tlctr-node-delete', this.index, this.node);
      }
    },
    deleteChildNode(index, node) {
      let newNodeToSelect = this.node[this.childrenDataProperty][index + 1];
      if (!newNodeToSelect) {
        newNodeToSelect = this.node[this.childrenDataProperty][index - 1];
      }
      if (!newNodeToSelect) {
        newNodeToSelect = this.node;
      }
      Vue.delete(this.node[this.childrenDataProperty], index);
      console.log("Node deleted: ", index, node);
      this.$emit('tlctr-node-order-changed');
      this.$emit('tlctr-select-new-node', newNodeToSelect);
    },
    shiftNodeUp() {
      this.$emit('tlctr-node-shift-up', this.index);
    },
    shiftNodeDown() {
      this.$emit('tlctr-node-shift-down', this.index);
    },
    shiftChildNodeUp(index) {
      console.log(index);
      if (index > 0) {
        let bottomItem = this.node[this.childrenDataProperty][index]; // temporarily remember item
        let topItem = this.node[this.childrenDataProperty][index - 1]; // temporarily remember item
        Vue.set(this.node[this.childrenDataProperty], index - 1, bottomItem); // insert at new location
        Vue.set(this.node[this.childrenDataProperty], index, topItem); // insert at new location
        this.$emit('tlctr-node-order-changed');
      }
    },
    shiftChildNodeDown(index) {
      console.log(index);
      if (index < this.node[this.childrenDataProperty].length - 1) {
        let bottomItem = this.node[this.childrenDataProperty][index]; // temporarily remember item
        let topItem = this.node[this.childrenDataProperty][index + 1]; // temporarily remember item
        Vue.set(this.node[this.childrenDataProperty], index + 1, bottomItem); // insert at new location
        Vue.set(this.node[this.childrenDataProperty], index, topItem); // insert at new location
        this.$emit('tlctr-node-order-changed');
      }
    },
    selectNewNode(newNode) {
      treeListCtrlMessageBus.$emit('tlctr-select-new-node', newNode)
    },
    notifyContextMenuOpened() {
      // console.log("notifyContextMenuOpened (node.Type, ref): ", this.node.Type, this.$refs.menu);
      treeListCtrlMessageBus.$emit('opened-context-menu', this.$refs.menu);
      this.handleNodeClick(this.node);  // select this node if it's right clicked on
    },
    contextMenuOpenedForNode(ref) {
      // console.log("received notification of context menu opened for node: ", node);
      if(this.$refs.menu === ref) {
        return;
      } // otherwise close ours
      if (this.$refs.menu) {
        this.$refs.menu.close();
      }
    },
    deepCopy(inObject) { /* can pass in an object or an array */
      // see https://medium.com/javascript-in-plain-english/how-to-deep-copy-objects-and-arrays-in-javascript-7c911359b089
      let outObject, value, key

      if (typeof inObject !== "object" || inObject === null) {
        return inObject // Return the value if inObject is not an object
      }

      // Create an array or object to hold the values
      outObject = Array.isArray(inObject) ? [] : {}

      for (key in inObject) {
        value = inObject[key]

        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = this.deepCopy(value)
      }

      return outObject
    },
  },
  components: {
    VueContext,
    // ClickToEdit
  },
  beforeMounted() {

  },
  mounted() {},
  created() {
    this.showChildren = this.expandNodes;
    treeListCtrlMessageBus.$on('opened-context-menu', this.contextMenuOpenedForNode)
  },
};
</script>

<style lang="stylus" scoped>
@import '~vue-context/dist/css/vue-context.css';

.selectedColor {
  background: var(--my-primary-color);
}

.v-context {
  &, & ul {
    background-color: #fff;
    border-radius: .25rem;
    padding: 8px 0;

    > li {
      > a {
        padding: .3rem 1.5rem;
        font-size: 0.9em;
        color: #000;
        &:hover,
        &:focus {
            color: #000
            background-color: var(--my-primary-color);
        }
      }
    }

    &:focus {
      outline: 0;
    }
  }
}

</style>
