<template>
  <div>
    <style-edit-field-label :label="label" />
    <input
      type="checkbox"
      id="checkbox"
      :checked="value"
      v-on:input="$emit('value-changed', $event.target.checked)"
    />
    <i
      v-if="tooltip"
      class="fa fa-info-circle f6"
      style="background-color: light-grey; border: 0px; width: auto; margin-left: 5px;"
      v-b-tooltip.hover
      :title="tooltipTxt"
    />
  </div>
</template>

<script>
import StyleEditFieldLabel from '@/components/StyleEditor/StyleEditFieldLabel.vue';
export default {
  name: 'StyleEditBoolean',
  model: {
    prop: 'value',
    event: 'value-changed',
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    tooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltipTxt: {
      type: String,
      required: false,
      default: 'No tooltip defined.',
    },
  },
  data() {
    return {};
  },
  components: {
    StyleEditFieldLabel,
  },
};
</script>
