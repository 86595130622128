<template>
  <span class="f4 mr2">
    {{ label }}
  </span>
</template>

<script>
export default {
  name: 'StyleEditFieldLabel',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
