<template>
  <div class="ml2">

    <div class="ml0">
      <style-edit-boolean
        label="Enabled"
        v-model="localFieldText.Enabled"
        @value-changed="updateStyle"
      />
      <div>
        <style-edit-field-label label="Field" />
        <input v-model="localFieldText.Field" @change="updateStyle" class="w3 f5" />
      </div>
      <div>
        <style-edit-field-label label="Units" class="" />
        <input v-model="localFieldText.Units" @change="updateStyle" class="w3 f5" />
      </div>
    </div>
    <div>
      <style-edit-boolean
        label="Include Label"
        v-model="localFieldText.IncludeLabel"
        @value-changed="updateStyle"
      />
      <div v-if="fieldText.IncludeLabel">
        <style-edit-field-label label="Custom Label" />
        <input
          v-model="localFieldText.CustomLabel"
          @change="updateStyle"
          class="w3 f5"
        />
      </div>
    </div>
    <span class="f5"
      ><pre>{{ localFieldText }}</pre></span
    >
  </div>
</template>

<script>
import StyleEditFieldLabel from '@/components/StyleEditor/StyleEditFieldLabel.vue';
import StyleEditBoolean from '@/components/StyleEditor/StyleEditBoolean.vue';
export default {
  name: 'StyleEditLabel',
  props: {
    fieldText: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localFieldText: this.fieldText,
    };
  },
  watch: {
    localLabel: function(newVal, oldVal) {
      console.log('Edited localFieldText (new, old): ', newVal, oldVal);
    },
  },
  created() {},
  methods: {
    updateStyle() {
      console.log('StyleEditFieldText, valueChange: ', this.localFieldText);
      this.$emit('value-changed', this.localFieldText);
    },
  },
  components: {
    StyleEditFieldLabel,
    StyleEditBoolean,
  },
};
</script>
