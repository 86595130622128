<template>
  <div class="layerdetail container-fluid">
    <h1 class="page-header primary">
      Layer Details
      <i v-if="layer.IsLocked" class="pl2 f3 black fa fa-lock" />
      <i v-else class="pl2 f3 black fa fa-unlock" />
    </h1>

    <!-- <p class="f3 fw5 tracked">Name: {{layer.Name}}</p> -->

    <div v-if="layer" class="table-responsive table--no-card m-b-40">
      <table class="table table-borderless table-striped">
        <tbody>
          <tr>
            <td>Name</td>
            <td class="">
              <span v-if="!canEdit()">{{ layer.Name }}</span>
              <span v-else>
                <ClickToEdit
                  :value="layer.Name"
                  :valueAuxData="layer.ID"
                  eventToEmit="event-layer-detail-name-change"
                ></ClickToEdit>
              </span>
            </td>
          </tr>
          <tr>
            <td>Description</td>
            <td>
              <span v-if="canEdit()">
                <ClickToEdit
                  :value="layer.Description"
                  :valueAuxData="layer.ID"
                  eventToEmit="event-layer-detail-description-change"
                />
              </span>
              <span v-else>{{ layer.Description }}</span>
            </td>
          </tr>
          <tr>
            <td>ID</td>
            <td>{{ layer.ID }}</td>
          </tr>
          <tr>
            <td>Channel Name | ID</td>
            <td>
              <router-link
                class="gray hover-gold"
                v-bind:to="'/channels/' + layer.ChannelID"
                >{{ layer.ChannelName }}
                <div class="f7">{{ layer.ChannelID }}</div>
              </router-link>
            </td>
          </tr>
          <tr>
            <td>Project Name | ID</td>
            <td>
              <router-link
                class="gray hover-gold"
                :to="'/projects/' + layer.ProjectID"
              >
                {{ projectName }}
                <div class="f7">{{ layer.ProjectID }}</div>
              </router-link>
            </td>
          </tr>
          <tr>
            <td>Owner Name | ID</td>
            <td>
              <router-link
                class="gray hover-gold"
                v-bind:to="'/users/' + layer.OwnerID"
              >
                {{ layer.OwnerName }}
                <div class="f7">{{ layer.OwnerID }}</div>
              </router-link>
            </td>
          </tr>
          <tr>
            <td>IsLocked</td>
            <td>{{ layer.IsLocked }}</td>
          </tr>
          <tr>
            <td>Created</td>
            <td>{{ layer.CreatedAt }}</td>
          </tr>
          <tr>
            <td>Updated</td>
            <td>{{ layer.UpdatedAt }}</td>
          </tr>
          <tr>
            <td>Style Definitions</td>
            <td>
              <router-link
                class="gray hover-gold"
                :to="{
                  name: 'styleeditor',
                  params: {
                    projectID: this.$route.params.ProjectID,
                    projectName: this.$route.query.projectName,
                    layerID: this.layer.ID
                      ? this.layer.ID
                      : this.$route.params.id,
                    layerName: layer.Name ? this.layer.Name : '',
                    layerObj: this.layer,
                  },
                }"
              >
                Go to Style Editor
                <i
                  class="fa fa-paint-brush f5"
                  style="
                    background-color: light-grey;
                    border: 0px;
                    width: auto;
                    margin-left: 5px;
                  "
                  v-b-tooltip.hover
                  title="Go to detailed Style Editor page"
                />
              </router-link>
              <!-- <pre>{{ layer.StyleDefinitions }}</pre> -->
              <JSONViewer
                :json="layer.StyleDefinitions"
                label="Style Definitions JSON list"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <JSONViewer :json="layer" label="Layer Raw JSON" />

      <!-- <style-definitions v-bind:styles="layer.StyleDefinitions" /> -->

      <GeofeatureMap :geofeatures="geofeatures" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Helpers from "@/helpers.js";
import ClickToEdit from "@/components/ClickToEdit.vue";
import { messageBus } from "@/main";
import { mapGetters } from "vuex";
import GeofeatureMap from "@/components/GeofeatureMap.vue";
import JSONViewer from "@/components/JSONViewer.vue";

export default {
  name: "LayerDetail",
  data() {
    return {
      layer: {},
      projectName: "",
      geofeatures: [],
    };
  },
  computed: mapGetters(["getSessionObj"]),
  methods: {
    treeNodeClicked(node) {
      console.log("LD node clicked: ", node.id);
    },
    collapseTree() {
      this.$refs.treeCtrl.collapseAllNodes();
    },
    expandTree() {
      this.$refs.treeCtrl.expandAllNodes();
    },
    fetchLayer(id, projectID) {
      console.log("fetching layer with id: ", id, " for prj: ", projectID);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + "/projects/" + projectID + "/layers/" + id, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(function (response) {
          console.log("Success getting /layers/:id ", response.bodyText);
          this.layer = response.body;
          this.fetchChannelNameForLayer();
          this.fetchLayerOwner();
          this.getGeofeaturesForChannel(this.layer.ChannelID).then(
            (response) => {
              response.forEach((element) => {
                let parsedGeofeature = Helpers.parseGeofeature(element);
                if (
                  parsedGeofeature != null &&
                  !this.geofeatures.includes(parsedGeofeature, 0)
                ) {
                  this.geofeatures.push(parsedGeofeature);
                }
              });
              messageBus.$emit("event-geofeatures-populated");
            },
            (response) => {
              // Fail
              console.log("Failed to get /layers/:id ", response.bodyText);
              Helpers.showAlert("ERROR: Couldn't retrieve /layers/:id");
            }
          );
        });
    }, // end fetch projects
    fetchChannelNameForLayer() {
      if (!this.layer.ChannelID) {
        return; // no valid Channel for this layer, so just return
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + "/channels/" + this.layer.ChannelID, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log("Success getting /channels/+id", response.bodyText);
            this.$set(this.layer, "ChannelName", response.body.Name);
          },
          (response) => {
            // Fail
            console.log("Failed to get /channels/+id name ", response.bodyText);
            Helpers.showAlert(
              "ERROR: Couldn't retrieve channel name for a layer"
            );
          }
        );
    },
    fetchLayerOwner() {
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + "/users/" + this.layer.OwnerID, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log("Success getting /users/+id", response.body);
            this.$set(this.layer, "OwnerName", response.body.Name);
          },
          (response) => {
            // Fail
            console.log("Failed to get /users/+id ", response.body);
            Helpers.showAlert("ERROR: Couldn't retrieve /users/+id");
          }
        );
    },
    handleEventLayerDetailDescriptionChange(msg) {
      if (
        msg.valueAuxData === this.$route.params.id &&
        msg.valueAuxData === this.layer.ID
      ) {
        this.layer.Description = msg.value;
        this.$set(this.layer, "Description", msg.value);
        this.putLayer();
      }
    },
    handleEventLayerDetailNameChange(msg) {
      if (
        msg.valueAuxData === this.$route.params.id &&
        msg.valueAuxData === this.layer.ID
      ) {
        this.layer.Name = msg.value;
        this.$set(this.layer, "Name", msg.value);
        this.putLayer();
      }
    },
    putLayer() {
      console.log("updating layer name for layer: ", this.layer.Name);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      let url =
        this.getSessionObj.apiURL + "/projects/" + this.$route.params.ProjectID;
      console.log("URL: ", url);

      this.$http
        .put(url + "/layers/" + this.$route.params.id, this.layer, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function (response) {
            console.log("Success updating layer", response.bodyText);

            // could I just call this.$forceUpdate() instead?
            this.fetchLayer(
              this.$route.params.id,
              this.$route.params.ProjectID
            );
          },
          (response) => {
            // Fail
            console.log("Failed to update layer name ", response.bodyText);
            Helpers.showAlert(
              "ERROR: Failed to update layer " + response.bodyText
            );
          }
        );
    },
    canEdit() {
      // you can edit if the channel is
      //  (!channelLocked) AND (youAreOwner || youAreAdmin)
      const youAreOwner =
        this.getSessionObj.userID === this.layer.OwnerID ? true : false;
      const youAreAdmin = Helpers.isAdmin();

      if (!this.layer.IsLocked && (youAreOwner || youAreAdmin)) {
        return true;
      }
      return false;
    },
    getGeofeaturesForChannel(channelID) {
      console.log("getting geofeatures for ", channelID);
      if (!channelID) {
        console.log("  invalid channelID, skipping GET on geofeatures");
        return;
      }
      return this.$store
        .dispatch("apiGetData", {
          url: "/channels/" + channelID + "/geofeatures",
          commit: false,
          errorAlert: "ERROR: Could not retrieve geofeatures for channel",
        })
        .then((response) => {
          console.log("retrieved geofeatures for ", channelID);
          return response.Items;
        });
    },
  },
  created: function () {
    console.log("CREATED: /project/:id/layers/:id");
    Helpers.doViewCreation(this);
    if (this.$route.query.projectName) {
      this.projectName = this.$route.query.projectName;
    }
    this.fetchLayer(this.$route.params.id, this.$route.params.ProjectID);

    messageBus.$on(
      "event-layer-detail-name-change",
      this.handleEventLayerDetailNameChange
    );

    messageBus.$on(
      "event-layer-detail-description-change",
      this.handleEventLayerDetailDescriptionChange
    );
  },
  mounted: function () {},
  components: {
    ClickToEdit,
    GeofeatureMap,
    // StyleDefinitions
    JSONViewer,
  },
};
</script>
