var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"styleeditor container-fluid"},[_c('h1',{staticClass:"page-header primary"},[_vm._v(" Style Editor ")]),(_vm.layer)?_c('div',{staticClass:"table-responsive table--no-card w-auto"},[_c('table',{staticClass:"table"},[_c('tbody',{},[_c('tr',{staticClass:"bg-white"},[_c('td',[_c('span',{staticClass:"b"},[_vm._v("Layer: ")]),_c('router-link',{staticClass:"gray hover-gold",attrs:{"to":{
                name: 'layerdetail',
                params: {
                  ProjectID: _vm.projectID,
                  id: _vm.layerID,
                },
                query: { projectName: _vm.localProjectName },
              }}},[(_vm.layerName != '')?_c('span',{staticClass:"f6 bg-near-white"},[_vm._v(_vm._s(_vm.layerName))]):_c('span',{staticClass:"f6 bg-near-white"},[_vm._v("<empty name>")]),_c('br'),_c('span',{staticClass:"f6"},[_vm._v("ID: ")]),_c('span',{staticClass:"f6 bg-near-white"},[_vm._v(_vm._s(_vm.layerID))])])],1),_c('td',[_c('span',{staticClass:"b"},[_vm._v("Project: ")]),_c('router-link',{staticClass:"gray hover-gold",attrs:{"to":{
                name: 'projectdetail',
                params: {
                  id: _vm.projectID,
                },
              }}},[(_vm.localProjectName != '')?_c('span',{staticClass:"f6 bg-near-white"},[_vm._v(_vm._s(_vm.localProjectName))]):_c('span',{staticClass:"f6 bg-near-white"},[_vm._v("<empty name>")]),_c('br'),_c('span',{staticClass:"f6"},[_vm._v("ID: ")]),_c('span',{staticClass:"f6 bg-near-white"},[_vm._v(_vm._s(_vm.projectID))])])],1)])])])]):_vm._e(),_c('div',{staticClass:"mb2"},[_c('span',{staticClass:"f3 fw5 tracked"},[_vm._v(" Style Definitions "),(_vm.layer.StyleDefinitions)?_c('span',{staticClass:"f5"},[_vm._v("("+_vm._s(_vm.layer.StyleDefinitions.length)+")")]):_vm._e()]),_c('i',{staticClass:"fa fa-download f4 ml4 mr2",on:{"click":_vm.saveToFile}},[_c('span',{staticClass:"f5 ml2 blue underline b pointer"},[_vm._v("Save to File")])]),(_vm.isAdminOrOwner(_vm.layer.OwnerID))?_c('i',{staticClass:"fa fa-upload f4 ml3",on:{"click":_vm.triggerStyleUploadDialog}},[_c('span',{staticClass:"f5 ml2 blue underline b pointer"},[_vm._v("Import from File")]),_c('input',{ref:"styleImport",staticStyle:{"visibility":"hidden"},attrs:{"type":"file","id":"selectFiles","value":"Import","accept":"*.json, application/json"},on:{"change":_vm.toggleImportModal}}),_c('b-modal',{attrs:{"id":"styleImportModal","title":"Load Style From File"},on:{"ok":_vm.importFromFile}},[(
            _vm.importedStyleJSON.serviceUrl !=
              _vm.$store.getters.getSessionObj.apiURL
          )?_c('div',[_c('span',{staticClass:"b red"},[_vm._v(" Warning: Style file is from a different instance ")])]):_vm._e(),(
            _vm.importedStyleJSON.serviceVersion != _vm.$store.getters.getBuildInfo
          )?_c('div',[_c('span',{staticClass:"b red"},[_vm._v(" Warning: Style file is from a different service version ")])]):_vm._e(),(_vm.importedStyleJSON.webAppVersion != _vm.$store.getters.getVersion)?_c('div',[_c('span',{staticClass:"b red"},[_vm._v(" Warning: Style file is from a different WebUI version ")])]):_vm._e(),_c('p',{staticClass:"mv2"},[_vm._v(" Clicking 'OK' will replace any existing Style definition with those in the file you've provided ")])])],1):_vm._e()]),_c('article',{staticClass:"cf"},[_c('div',{staticClass:"fl w-auto h-100 bg-near-white"},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.d800",modifiers:{"hover":true,"d800":true}}],staticClass:"f3 fa fa-caret-square-o-right ml2 mr1 mt3",attrs:{"title":"Collapse Tree"},on:{"click":_vm.collapseTree}}),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.d800",modifiers:{"hover":true,"d800":true}}],staticClass:"f3 fa fa-caret-square-o-down mh1 mt3",attrs:{"title":"Expand Tree"},on:{"click":_vm.expandTree}}),_c('hr'),_c('tree-list-ctrl',{ref:"treeCtrl",staticStyle:{"min-width":"175px"},attrs:{"treeData":_vm.layer.StyleDefinitions,"labelDataProperty":"Type","childrenDataProperty":"Children","handleBeforeDelete":_vm.handleBeforeDelete,"handleBeforeInsert":_vm.handleBeforeInsert},on:{"tlctr-insert-above":_vm.insertNodeAbove,"tlctr-node-click":_vm.treeNodeClicked,"tlctr-node-order-changed":_vm.updateStyle,"tlctr-node-inserted":_vm.updateStyleWithInsert}})],1),_c('div',{staticClass:"fl w-auto bg-white mh3"},[_c('StyleEdit',{attrs:{"selectedNode":_vm.selectedNode},on:{"value-changed":_vm.updateNode}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }