<template>
  <div class="ml2">

    <div class="ml0">
      <style-edit-boolean
        label="Enabled"
        v-model="localSprite.Enabled"
        @value-changed="updateStyle"
      />
      <style-edit-field-label label="Offset:" class="" />
      <div>
        <style-edit-field-label label="Scale" class="ml4" />
        <input
          @change="updateStyle()"
          class="w3 f5"
          v-model.number="localSprite.OffsetScale"
          @value-changed="updateStyle"
        />
        <style-edit-field-label label="Angle (in degrees)" class="ml4" />
        <input
          @change="updateStyle()"
          class="w3 f5"
          v-model.number="localSprite.OffsetAngleD"
          @value-changed="updateStyle"
        />
      </div>

      <style-edit-field-label label="Diagonal Size" class="" />
      <input
        @change="updateStyle()"
        class="w3 f5"
        v-model.number="localSprite.DiagonalSize"
        @value-changed="updateStyle"
      />
      <br />

      <style-edit-field-label label="Sprite" />
      <select v-if="this.$store.getters.getLoadingSprites" class="w5 f5">
        <option disabled>Loading</option>
      </select>
      <select
        v-if="!this.$store.getters.getLoadingSprites"
        v-model="localSprite.SpriteID"
        class="w5 f5"
        @change="updateStyle"
      >
        <option disabled :value="null">Please select one</option>
        <option
          v-for="sp in spriteList"
          v-bind:key="sp.ID"
          :value="sp.ID"
          >{{ sp.Name }} ({{ sp.ID }})</option
        >
      </select>
      <br />
      <div>
        <style-edit-field-label label="Sprite Preview" />
        <br />
        <div class="ml2 w5" v-if="!this.$store.getters.getLoadingSprites">
          <img
            style="height: auto; width: auto"
            :src="getImageData(localSprite.SpriteID)"
          />
        </div>
      </div>

      <hr />

      <pre class="f6">{{ localSprite }}</pre>
    </div>
  </div>
</template>

<script>
import StyleEditFieldLabel from '@/components/StyleEditor/StyleEditFieldLabel.vue';
import StyleEditBoolean from '@/components/StyleEditor/StyleEditBoolean.vue';
export default {
  name: 'StyleEditSprite',
  props: {
    sprite: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localSprite: this.sprite,
    };
  },
  watch: {
    localSprite: function(newVal, oldVal) {
      console.log('Edited localSprite (new, old): ', newVal, oldVal);
    },
    sprite() {
      this.localSprite = this.sprite;
    }
  },
  computed: {
    spriteList() {
      return this.$store.getters.getSprites;
    },
  },
  created() {},
  methods: {
    updateStyle() {
      console.log('StyleEditTransform, valueChange: ', this.localSprite);
      this.$emit('value-changed', this.localSprite);
    },
    getImageData(spriteID) {
      return (
        'data:image/jpeg;base64,' +
        this.spriteList.filter(sprite => sprite.ID == spriteID)[0].ImageData
      );
    },
  },
  components: {
    StyleEditFieldLabel,
    StyleEditBoolean,
  },
};
</script>
