<template>

  <div> <!-- start div for showing the node -->

        <div v-if="selectedNodeIsEmpty">
          <span class="f3 ml2 tracked b">No Item Selected</span>
        </div>

        <div v-else class="f3 ml2">
          <span class="tracked b" v-if="selectedNode.Type">{{selectedNode.Type}}</span>
          <span class="tracked b" v-if="selectedNode.DisplayName"> - {{ selectedNode.DisplayName }}</span>
          <!-- <span class="f5 ml4 v-mid">Change Type</span> -->
        </div>


        <div v-if="!selectedNodeIsEmpty">
          <div v-if="selectedNode.Type == 'Group'">
            <div class="f3">
              <style-edit-group
                :group="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'Transform'">
            <div class="f3">
              <style-edit-transform
                :transform="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'Color'">
            <div class="f3">
              <style-edit-color
                :node="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'LineString'">
            <div class="f3">
              <style-edit-line-string
                :linestring="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'Label'">
            <div class="f3">
              <style-edit-label
                :label="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'Sprite'">
            <div class="f3">
              <style-edit-sprite
                :sprite="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'Shape'">
            <div class="f3">
              <style-edit-shape
                :shape="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'DataGrid'">
            <div class="f3">
              <style-edit-data-grid
                :datagrid="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'Point'">
            <div class="f3">
              <style-edit-point
                :point="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'ColorMap'">
            <div class="f3">
              <style-edit-color-map
                :colorMap="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'SurfaceObject'">
            <div class="f3">
              <style-edit-surface-object
                :surface="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'InitialPointShape'">
            <div class="f3">
              <style-edit-initial-point-shape
                :shape="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'TerminalPointShape'">
            <div class="f3">
              <style-edit-terminal-point-shape
                :shape="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'CustomText'">
            <div class="f3">
              <style-edit-custom-text
                :text="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'MaxVisibleDistance'">
            <div class="f3">
              <style-edit-max-visible-distance
                :maxDistance="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'FieldText'">
            <div class="f3">
              <style-edit-field-text
                :fieldText="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'OwnerText'">
            <div class="f3">
              <style-edit-owner-text
                :text="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else-if="selectedNode.Type == 'MinScaleDistance'">
            <div class="f3">
              <style-edit-min-scale-distance
                :minDistance="selectedNode"
                @value-changed="updateNode"
              />
            </div>
          </div>
          <div v-else>
            <div class="f3">!! Editing Not yet implemented !!</div>
            {{ selectedNode.Type }}
            <pre>
              {{ selectedNode }}
            </pre>
          </div>
        </div>
  </div>  <!-- end div for showing the node -->

</template>

<script>
import StyleEditGroup from '@/components/StyleEditor/StyleEditGroup.vue';
import StyleEditTransform from '@/components/StyleEditor/StyleEditTransform.vue';
import StyleEditColor from '@/components/StyleEditor/StyleEditColor.vue';
import StyleEditLineString from '@/components/StyleEditor/StyleEditLineString.vue';
import StyleEditLabel from '@/components/StyleEditor/StyleEditLabel.vue';
import StyleEditSprite from '@/components/StyleEditor/StyleEditSprite.vue';
import StyleEditShape from '@/components/StyleEditor/StyleEditShape.vue';
import StyleEditDataGrid from '@/components/StyleEditor/StyleEditDataGrid.vue';
import StyleEditPoint from '@/components/StyleEditor/StyleEditPoint.vue';
import StyleEditColorMap from '@/components/StyleEditor/StyleEditColorMap.vue';
import StyleEditSurfaceObject from '@/components/StyleEditor/StyleEditSurfaceObject.vue';
import StyleEditInitialPointShape from '@/components/StyleEditor/StyleEditInitialPointShape.vue';
import StyleEditTerminalPointShape from '@/components/StyleEditor/StyleEditTerminalPointShape.vue';
import StyleEditCustomText from '@/components/StyleEditor/StyleEditCustomText.vue';
import StyleEditFieldText from '@/components/StyleEditor/StyleEditFieldText.vue';
import StyleEditOwnerText from '@/components/StyleEditor/StyleEditOwnerText.vue';
import StyleEditMaxVisibleDistance from '@/components/StyleEditor/StyleEditMaxVisibleDistance.vue';
import StyleEditMinScaleDistance from '@/components/StyleEditor/StyleEditMinScaleDistance.vue';

export default {
  props: {
    selectedNode: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    selectedNodeIsEmpty() {
      if (
        Object.keys(this.selectedNode).length === 0 &&
        this.selectedNode.constructor === Object
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    updateNode(newNode) {
      if(newNode) {
        this.$emit('value-changed', newNode);
      } else {
        this.$emit('value-changed', this.selectedNode);
      }
    }
  },
  components: {
    StyleEditGroup,
    StyleEditTransform,
    StyleEditColor,
    StyleEditLineString,
    StyleEditLabel,
    StyleEditSprite,
    StyleEditShape,
    StyleEditDataGrid,
    StyleEditPoint,
    StyleEditColorMap,
    StyleEditSurfaceObject,
    StyleEditInitialPointShape,
    StyleEditTerminalPointShape,
    StyleEditCustomText,
    StyleEditFieldText,
    StyleEditOwnerText,
    StyleEditMaxVisibleDistance,
    StyleEditMinScaleDistance,
  }
}
</script>