<template>
  <div class="projectdetail container-fluid">
    <div class="dt w-100 page-header primary">
      <h1>
        <span class="page-header primary dtc v-mid w-100">Project Details</span>
      </h1>
      <span class="dtc v-mid tr">
        <div class="gray hover-gold">
          <i class="fa fa-qrcode" @click="showQRCode ? (showQRCode = false) : displayQRCode()" />
        </div>
      </span>
    </div>

    <modal
      name="passwordEntryModal"
      height="auto"
      width="500"
      @before-close="beforeCloseQRmodal"
      @before-open="beforeOpenQRmodal"
    >
      <div>
        <center>
          <span class="primary dtc v-mid w-100 f3 pa2">Generate QR Code</span>
        </center>
        <div class="ph4 f6 pb2 mw6 center">
          Enter your password. A QR code will be generated that you can use to
          launch the Android companion app directly to this project.
        </div>
        <div class="bg-light-gray w-90 center ph2 pt2 pb1 br4">
          <label for="password" class="f6 b mb2 mr3 fl w3">
            Password
            <span class="normal black-60">(required)</span>
          </label>
          <input
            id="password"
            class="input-reset ba b--black-20 pa2 mb2 w-70"
            :type="passwordFieldType"
            name="password"
            v-model="qrPassword"
            required
            autocomplete="password"
          />
          <!-- <button id="qrEye" type="button" style="background: url(font-awesome-svgs/eye.svg); width: 20px; height: 18px; border: 0px; margin-left: 5px;" v-on:click="togglePasswordType()" /> -->
          <span v-if="this.passwordFieldType === 'password'">
            <i
              id="qrEye"
              class="fa fa-eye"
              style="background-color: light-grey; border: 0px; margin-left: 5px;"
              @click="togglePasswordType()"
            />
          </span>
          <span v-else>
            <i
              id="qrEye"
              class="fa fa-eye-slash"
              style="background-color: light-grey; border: 0px; margin-left: 5px;"
              @click="togglePasswordType()"
            />
          </span>
        </div>

        <div class="center w-90 pt2">
          <center>
            <button
              class="f6 dim primary ph3 pv2 br3 mb2 mh2 b bg-white"
              type="button"
              v-on:click="displayQRCode()"
            >Ok</button>
          </center>
        </div>
      </div>
    </modal>

    <div v-if="showQRCode">
      <center>
        <div class="w-20">
          <i class="fa fa-gear fr" @click="displayQRPasswordEntryDialog" />
          <i class="fa fa-close fl" @click="showQRCode = false" />
          <QRDeepLink
            v-if="true"
            :username="this.getSessionObj.username"
            :password="qrPassword"
            :url="this.getSessionObj.apiURL"
            :projectID="project.ID"
          />
        </div>
      </center>
    </div>

    <p class="f3 fw5 tracked">Name: {{ project.Name }}</p>

    <div class="table-responsive table--no-card m-b-40">
      <table class="table table-borderless table-striped">
        <tbody>
          <tr>
            <td>Name</td>
            <td>{{ project.Name }}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{{ project.Description }}</td>
          </tr>
          <tr>
            <td>ID</td>
            <td>{{ project.ID }}</td>
          </tr>
          <tr>
            <td>Owner Name/ID</td>
            <td>
              <router-link class="gray hover-gold" v-bind:to="'/users/' + project.OwnerID">
                {{ projectOwnerName }}
                <div class="f7">{{ project.OwnerID }}</div>
              </router-link>
            </td>
          </tr>
          <tr>
            <td>Created</td>
            <td>{{ project.CreatedAt }}</td>
          </tr>
          <tr>
            <td>Updated</td>
            <td>{{ project.UpdatedAt }}</td>
          </tr>
          <tr>
            <td>Default Pose</td>
            <td>
              (elev,lat,long): {{ project.DefaultPose.Location.ElevM }},
              {{ project.DefaultPose.Location.LatD }},
              {{ project.DefaultPose.Location.LngD }}
              <br />
              (w,x,y,z): {{ project.DefaultPose.Rotation.W }},
              {{ project.DefaultPose.Rotation.X }},
              {{ project.DefaultPose.Rotation.Y }},
              {{ project.DefaultPose.Rotation.Z }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <JSONViewer :json="project" />

    <p class="f3 mt4 fw5 tracked">
      Layers for project {{ project.Name }}
      <span class="f5">({{ layers.Items.length }})</span>
      <SearchBox
        class="fr f6"
        :searchList="layers.Items"
        placeholder="search layers"
        :linkBase="$route.path + '/layers'"
        :linkQueryParamsObj="{ projectName: project.Name }"
      />
    </p>

    <router-link to="">
      <span>
        <button
          @click="performDelete"
          v-if="checkedIDs.length"
          type="button"
          id="trash"
          class="mr1"
          style="background: url(../font-awesome-svgs/trash-alt.svg); background-size: 18px 18px; width: 18px; height: 18px; border: 0px; margin-left: 5px;"
        />

        <span @click="performDelete" v-if="checkedIDs.length" class="mr4">
          Delete Selected <span class="f5">({{ checkedIDs.length }})</span>
        </span>
      </span>
    </router-link>

    <router-link :to="$route.params.id + '/addLayer'">
      <button
        type="button"
        id="add"
        style="background: url(../font-awesome-svgs/plus-square.svg); background-size: 18px 18px; width: 18px; height: 18px; border: 0px; margin-left: 5px;"
      />
      Add Layer
    </router-link>
    <div class="table-responsive table--no-card m-b-40">
      <table class="table table-borderless table-striped">
        <thead>
          <tr class="bg-gold">
            <th>
              <input
                type="checkbox"
                class=""
                v-model="deleteAllCheckBox"
                @change="toggleAllchecked($event)"
              />
              <!-- </template> -->
            </th>
            <th class="th-sm">Layer Name/ID</th>
            <th>Layer Description</th>
            <th>Channel Name/ID</th>
            <!-- <th>Channel Description</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in layers.Items" :key="item.ID">
            <td>
              <input
                type="checkbox"
                :id="item.ID"
                :value="item.ID"
                class=""
                v-model="checkedIDs"
                :disabled="
                  getSessionObj.userID === item.OwnerID ||
                  isAdmin()
                    ? false // if owner or admin, don't disable the checkbox
                    : 'disabled'
                "
              />
            </td>
            <td class="">
              <!-- <span v-if=item.IsLocked>{{item.Name}}</span>
              <ClickToEdit v-else :value=item.Name :valueAuxData=item eventToEmit="event-prj-detail-layer-name-change"></ClickToEdit>-->

              <i v-if="item.IsLocked" class="fa fa-lock" />
              <i v-else class="fa fa-unlock pr2" />
              <router-link
                v-if="item.Name"
                class="gray hover-gold"
                v-bind:to="{
                  path: '/projects/' + item.ProjectID + '/layers/' + item.ID,
                  query: { projectName: project.Name },
                }"
              >
                <span v-if="item.Name">{{ item.Name }}</span>
              </router-link>


              <router-link
                v-else
                class="gray hover-gold"
                v-bind:to="{
                  path: '/projects/' + item.ProjectID + '/layers/' + item.ID,
                  query: { projectName: project.Name },
                }"
              >&nbsp;</router-link>

              <router-link
                class="f6 gray hover-gold"
                v-bind:to="{
                  name: 'styleeditor',
                  params: {
                    projectID: project.ID,
                    projectName: project.Name,
                    layerID: item.ID,
                    layerName: item.Name,
                    layerObj: item,
                  },
                }"
              >
                <i
                  class="fa fa-paint-brush pr2 black f5 pl2"
                  v-b-tooltip.hover.d800
                  title="Go to detailed Style Editor page"
                />
                <!-- (style editor) -->
              </router-link>

              <div class="f7">
                <router-link
                  class="gray hover-gold"
                  v-bind:to="{
                    path: '/projects/' + item.ProjectID + '/layers/' + item.ID,
                    query: { projectName: project.Name },
                  }"
                >{{ item.ID }}</router-link>
              </div>

              <!-- <div class="f7">{{ item.ID }}</div> -->
            </td>

            <td>
              <router-link
                v-if="item.Description"
                class="gray hover-gold"
                v-bind:to="'/projects/' + item.ProjectID + '/layers/' + item.ID"
              >{{ item.Description }}</router-link>
            </td>

            <td>
              <router-link
                class="gray hover-gold"
                v-bind:to="'/channels/' + item.ChannelID"
              >{{ item.ChannelName }}</router-link>
              <div class="f7">
                <router-link
                  class="gray hover-gold"
                  v-bind:to="'/channels/' + item.ChannelID"
                >{{ item.ChannelID }}</router-link>
              </div>
            </td>

            <!-- <td>{{item.ChannelDescription}}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <GeofeatureMap :geofeatures="geofeatures" :mapCenter="project.DefaultPose.Location" />
  </div>
</template>

<script>
// @ is an alias to /src
import Helpers from "@/helpers.js";
import APIhelper from "@/api/apihelper.js";
import { messageBus } from "@/main";
import QRDeepLink from "@/components/QRDeepLink";
import SearchBox from "@/components/SearchBox.vue";
import { mapGetters } from "vuex";
import GeofeatureMap from "@/components/GeofeatureMap.vue";
import JSONViewer from '@/components/JSONViewer.vue';

export default {
  name: "ProjectDetail",
  data() {
    return {
      project: { DefaultPose: { Location: {}, Rotation: {} } },
      projectOwnerName: "",
      layers: { Items: [] },
      showQRCode: false,
      qrPassword: "",
      passwordFieldType: "password",
      qrPasswordDialogIsActive: false,
      deleteAllCheckBox: false,
      checkedIDs: [],
      geofeatures: [],
    };
  },
  computed: mapGetters(["getSessionObj", "getSessionPassword"]),
  methods: {
    displayQRPasswordEntryDialog() {
      this.$modal.show("passwordEntryModal");
    },
    displayQRCode() {
      if (this.qrPasswordDialogIsActive) {
        // close dialog
        // show QR code
        this.$modal.hide("passwordEntryModal");

        if (!this.qrPassword) {
          this.showQRCode = false;
        } else {
          this.showQRCode = true;
          // If sessionPassword is empty, then the user reloaded the app in their browser
          // We don't remember their password, but we did remember their token (NEVER write
          // passwords to disk!).. so, let's take the password they just entered and hold
          // it in memory so we can generate QR codes in subsequent project pages.
          if (!this.getSessionPassword) {
            let b64Password = Helpers.base64Encode(this.qrPassword);
            this.$store.commit("MUTATE", {
              property: "sessionPassword",
              with: b64Password
            });
          }
        }

        return;
      }

      // dialog isn't active, so see if need to prompt for password
      // if password empty, prompt for password
      if (!this.qrPassword && !this.showQRCode) {
        return this.displayQRPasswordEntryDialog();
      }

      // show QR code
      this.$modal.hide("passwordEntryModal");
      this.showQRCode = true;
    },
    beforeOpenQRmodal() {
      this.qrPasswordDialogIsActive = true;
    },
    beforeCloseQRmodal() {
      console.log(event);
      // If modal was open less then 5000 ms - prevent closing it
      if (this.time + this.duration < Date.now()) {
        event.stop();
      }
      this.qrPasswordDialogIsActive = false;
    },
    resetQRCode() {
      this.qrPassword = Helpers.base64Decode(this.getSessionPassword);
      this.showQRCode = false;
      this.displayQRPasswordEntryDialog();
    },
    togglePasswordType() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    }, // end togglePasswordType
    fetchProject(id) {
      console.log("fetching project with id: ", id);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + "/projects/" + id, {
          headers: { authorization: this.getSessionObj.access_token }
        })
        .then(
          function(response) {
            console.log("Success getting /projects:" + id, response.bodyText);
            this.project = response.body;
            APIhelper.fetchUser(this.project.OwnerID).then(ret => {
              console.log("Owner name for project: ", ret);
              this.projectOwnerName = ret.Name;
            });
          },
          response => {
            // Fail
            console.log("Failed to get /projects/:id ", response.bodyText);
            Helpers.showAlert("ERROR: Couldn't retrieve /projects/:id");
          }
        );
    }, // end fetch projects
    fetchChannelNameForLayer(layerIndex) {
      if(!this.layers.Items[layerIndex].ChannelID) {
        return; // no valid Channel for this layer, so just return
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .get(url + "/channels/" + this.layers.Items[layerIndex].ChannelID, {
          headers: { authorization: this.getSessionObj.access_token }
        })
        .then(
          function(response) {
            console.log("Success getting /channels/+id", response.bodyText);
            this.layers.Items[layerIndex].ChannelName = response.body.Name;
            this.layers.Items[layerIndex].ChannelDescription =
              response.body.Description;
            this.$forceUpdate();
          },
          response => {
            // Fail
            console.log("Failed to get /channels/+id name ", response.bodyText);
            Helpers.showAlert(
              "ERROR: Couldn't retrieve channel name for a layer"
            );
          }
        );
    },
    fetchChannelNamesForLayers() {
      console.log("fetching channel names for layers");
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let i = 0;

      for (i = 0; i < this.layers.Items.length; i++) {
        console.log(
          "grabbing channel name for layer: ",
          this.layers.Items[i].Name
        );
        this.fetchChannelNameForLayer(i);
      } // end 4 loop going through layers
    },
    fetchProjectLayers(id) {
      console.log("fetching project layers for project with id: ", id);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = this.getSessionObj.apiURL + "projects/" + id;
      console.log("URL: ", url);
      this.$http
        .get(url + "/layers", {
          headers: { authorization: this.getSessionObj.access_token }
        })
        .then(
          function(response) {
            console.log("Success getting /layers:", response.bodyText);
            this.layers = response.body;
            this.fetchChannelNamesForLayers();
            this.fetchGeofeaturesForProject();
          },
          response => {
            // Fail
            console.log("Failed to get /layers ", response.bodyText);
            Helpers.showAlert("ERROR: Couldn't retrieve /layers");
          }
        );
    },
    fetchGeofeaturesForProject() {
      console.log("fetching geofeatures for project");
      this.layers.Items.forEach(layer => {
        if (!layer.ChannelID) return;
        this.getGeofeaturesForChannel(layer.ChannelID).then(response => {
          response.forEach(element => {
            let parsedGeofeature = Helpers.parseGeofeature(element);
            if (
              parsedGeofeature != null &&
              !this.geofeatures.includes(parsedGeofeature, 0)
            ) {
              this.geofeatures.push(parsedGeofeature);
            }
          });
          // messageBus.$emit('event-geofeatures-populated');
        });
      });
    },
    getGeofeaturesForChannel(channelID) {
      console.log("getting geofeatures for ", channelID);
      if(!channelID) {
        console.log("  invalid channelID, skipping GET on geofeatures");
        return;
      }
      return this.$store
        .dispatch("apiGetData", {
          url: "/channels/" + channelID + "/geofeatures",
          commit: false,
          errorAlert: "ERROR: Could not retrieve geofeatures for channel"
        })
        .then(response => {
          console.log("retrieved geofeatures for ", channelID);
          return response.Items;
        });
    },
    handleEventPrjDetailLayerNameChange(msg) {
      console.log("updating layer name for layer: ", msg.valueAuxData.Name);
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        ("Your API session has expired. Please login again.");
        return;
      }

      let url =
        this.getSessionObj.apiURL + "/projects/" + msg.valueAuxData.ProjectID;
      console.log("URL: ", url);
      msg.valueAuxData.Name = msg.value;

      this.$http
        .put(url + "/layers/" + msg.valueAuxData.ID, msg.valueAuxData, {
          headers: { authorization: this.getSessionObj.access_token }
        })
        .then(
          function(response) {
            console.log("Success updating layer", response.bodyText);
          },
          response => {
            // Fail
            console.log("Failed to update layer name ", response.bodyText);
            Helpers.showAlert(
              "ERROR: Failed to update layer " + response.bodyText
            );
          }
        );
    },
    performDelete() {
      this.$bvModal
        .msgBoxConfirm('Are you sure?  This action can not be undone.')
        .then(value => {
          console.log('DELETE CONFIRMATION VALUE: ', value);

          if (!value) {
            return;
          }

          this.deleteLayers(this.checkedIDs)

          this.checkedIDs = [];
          if (this.deleteAllCheckBox) {
            this.deleteAllCheckBox = false;
          }
        })
        .catch(err => {
          // An error occurred
          // skip delete
          console.log(err);
        });
    },
    deleteLayers(idArray) {
      console.log('deleting channel');
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }
      let url = this.getSessionObj.apiURL;
      this.$http
        .delete(url + '/projects/' + this.project.ID + '/layers', {
          body: idArray,
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function(response) {
            console.log('Success deleting layers: ', response.bodyText);
            this.fetchProjectLayers(this.$route.params.id); // slow way. TODO: just remove from local channels []
            Helpers.showAlert('Layers Deleted');
          },
          response => {
            // Fail
            console.log('Failed DELETE to /projects/:projectID/layers', response.bodyText);
            if (response.body) {
              Helpers.showAlert('Error: ' + response.bodyText);
            } else {
              Helpers.showAlert('Failed DELETE to /projects/:projectID/layers');
            }
          }
        );
    }, // end delete channel
    toggleAllchecked(event) {
      if (event.target.checked) {
        // select all
        this.layers.Items.forEach(layer => {
          if (
            this.checkedIDs.includes(layer.ID) === false &&
            (this.getSessionObj.userID === layer.OwnerID ||
              this.isAdmin())
          ) {
            this.checkedIDs.push(layer.ID);
          }
        });
      } else {
        // unselect all
        this.checkedIDs = [];
      }
    },
    isAdmin() {
      return Helpers.isAdmin();
    },
  },
  created: function() {
    // console.log("CREATED: /project");
    Helpers.doViewCreation(this);
    this.fetchProject(this.$route.params.id);
    this.fetchProjectLayers(this.$route.params.id);

    //this.fetchChannelNamesForLayers();

    this.qrPassword = Helpers.base64Decode(this.getSessionPassword);

    messageBus.$on(
      "event-prj-detail-layer-name-change",
      this.handleEventPrjDetailLayerNameChange
    );
  },
  mounted: function() {},
  components: { QRDeepLink, GeofeatureMap, SearchBox, JSONViewer },
};
</script>
