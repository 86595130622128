<template>
  <div class="styleeditor container-fluid">
    <h1 class="page-header primary">
      Style Editor
    </h1>

    <div v-if="layer" class="table-responsive table--no-card w-auto">
      <table class="table">
        <tbody class="">
          <tr class="bg-white">


            <td>
              <span class="b">Layer: </span>

              <router-link
                class="gray hover-gold"
                v-bind:to="{
                  name: 'layerdetail',
                  params: {
                    ProjectID: projectID,
                    id: layerID,
                  },
                  query: { projectName: localProjectName },
                }"
              >
                <span v-if="layerName != ''" class="f6 bg-near-white">{{
                  layerName
                }}</span>
                <span v-else class="f6 bg-near-white">&lt;empty name&gt;</span>
                <br />
                <span class="f6">ID: </span>
                <span class="f6 bg-near-white">{{ layerID }}</span>
              </router-link>
            </td>


            <td>
              <span class="b">Project: </span>
              <router-link
                class="gray hover-gold"
                v-bind:to="{
                  name: 'projectdetail',
                  params: {
                    id: projectID,
                  },
                }"
              >

                <span v-if="localProjectName != ''" class="f6 bg-near-white">{{
                  localProjectName
                }}</span>
                <span v-else class="f6 bg-near-white">&lt;empty name&gt;</span>
                <br />
                <span class="f6">ID: </span>
                <span class="f6 bg-near-white">{{ projectID }}</span>
              </router-link>
            </td>
          </tr>
          <!-- <tr>
            <td></td>
            <td></td>
          </tr> -->
        </tbody>
      </table>
    </div>

    <div class="mb2">
      <span class="f3 fw5 tracked">
        Style Definitions
        <span v-if="layer.StyleDefinitions" class="f5"
          >({{ layer.StyleDefinitions.length }})</span
        >
      </span>
      <i class="fa fa-download f4 ml4 mr2" @click="saveToFile">
        <span class="f5 ml2 blue underline b pointer">Save to File</span>
      </i>
      <i v-if="isAdminOrOwner(layer.OwnerID)" class="fa fa-upload f4 ml3" @click="triggerStyleUploadDialog">
        <span class="f5 ml2 blue underline b pointer">Import from File</span>
        <input
          type="file"
          id="selectFiles"
          value="Import"
          ref="styleImport"
          style="visibility:hidden"
          accept="*.json, application/json"
          @change="toggleImportModal"
        />

        <b-modal
          id="styleImportModal"
          title="Load Style From File"
          @ok="importFromFile"
        >
          <div
            v-if="
              importedStyleJSON.serviceUrl !=
                $store.getters.getSessionObj.apiURL
            "
          >
            <span class="b red">
              Warning: Style file is from a different instance
            </span>
          </div>
          <div
            v-if="
              importedStyleJSON.serviceVersion != $store.getters.getBuildInfo
            "
          >
            <span class="b red">
              Warning: Style file is from a different service version
            </span>
          </div>
          <div
            v-if="importedStyleJSON.webAppVersion != $store.getters.getVersion"
          >
            <span class="b red">
              Warning: Style file is from a different WebUI version
            </span>
          </div>

          <p class="mv2">
            Clicking 'OK' will replace any existing Style definition with those
            in the file you've provided
          </p>
        </b-modal>
      </i>
    </div>

    <article class="cf">
      <div class="fl w-auto h-100 bg-near-white">
        <!-- <button
          class="f6 dim primary b bg-white center ph3 pv2 mb2 br3"
          type="button"
        >
          Hello
        </button> -->

        <i
          class="f3 fa fa-caret-square-o-right ml2 mr1 mt3"
          @click="collapseTree"
          v-b-tooltip.hover.d800
          title="Collapse Tree"
        />
        <i
          class="f3 fa fa-caret-square-o-down mh1 mt3"
          @click="expandTree"
          v-b-tooltip.hover.d800
          title="Expand Tree"
        />
        <!-- <img
          src="@/assets/svgs/caret-square-right.svg"
          class="ml2 mr1 mt3"
          style="width: 25px;"
          @click="collapseTree"
        />
        <img
          src="@/assets/svgs/caret-square-down.svg"
          class="mh1 mt3"
          style="width: 25px;"
          @click="expandTree"
        /> -->
        <hr />

        <tree-list-ctrl
          style="min-width: 175px;"
          ref="treeCtrl"
          :treeData="layer.StyleDefinitions"
          labelDataProperty="Type"
          childrenDataProperty="Children"
          :handleBeforeDelete="handleBeforeDelete"
          :handleBeforeInsert="handleBeforeInsert"
          @tlctr-insert-above="insertNodeAbove"
          @tlctr-node-click="treeNodeClicked"
          @tlctr-node-order-changed="updateStyle"
          @tlctr-node-inserted="updateStyleWithInsert"
        />
      </div>
      <!-- Separator between TreeCtrl and Editor window -->
      <div class="fl w-auto bg-white mh3">
        <StyleEdit :selectedNode="selectedNode" @value-changed="updateNode"/>
      </div>

    </article>
  </div>
</template>

<script>
import Helpers from '@/helpers.js';
import { saveAs } from 'file-saver';
import TreeListCtrl from '@/components/TreeListCtrl.vue';
import StyleEdit from '@/components/StyleEditor/StyleEdit.vue';

export default {
  name: 'StyleEditor',
  props: {
    projectID: {
      type: String,
      required: true,
    },
    projectName: {
      type: String,
      required: false,
      default: '',
    },
    layerID: {
      type: String,
      required: true,
    },
    layerName: {
      type: String,
      required: false,
      default: '',
    },
    layerObj: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      layer: {},
      selectedNode: {},
      localProjectName: '',
      importedStyleJSON: {},
    };
  },
  computed: {
    selectedNodeIsEmpty() {
      if (
        Object.keys(this.selectedNode).length === 0 &&
        this.selectedNode.constructor === Object
      ) {
        return true;
      }
      return false;
    },
    sprites() {
      return this.$store.getters.getSprites;
    },
    shapes() {
      return this.$store.getters.getShapes;
    },
  },
  methods: {
    isAdminOrOwner(OwnerID) {
      return Helpers.isAdminOrOwner(OwnerID);
    },
    saveToFile() {
      // var blob = new Blob(["Hello, world!"], {type: "text/plain;charset=utf-8"});
      // saveAs(blob, "hello world.txt");
      console.log('saveToFile');
      let jsonData = {
        webAppVersion: this.$store.getters.getVersion,
        serviceUrl: this.$store.getters.getSessionObj.apiURL,
        serviceVersion: this.$store.getters.getBuildInfo,
        serviceUser: this.$store.getters.getSessionObj.username,
        serviceUserID: this.$store.getters.getSessionObj.userID,
        saveTimeStampUTC: new Date()
          .toISOString()
          .replace(/T/, ' ')
          .replace(/\..+/, ''),
        styleDefinitions: this.layer.StyleDefinitions,
      };
      const data = JSON.stringify(jsonData, null, 2);
      const blob = new Blob([data], { type: 'text/plain' });
      saveAs(blob, 'style.json');
    },
    triggerStyleUploadDialog() {
      this.$refs.styleImport.value = '';
      this.importedStyleJSON = {};
      this.$refs.styleImport.click();
    },
    importFromFile() {
      console.log('importFromFile');
      this.layer.StyleDefinitions = this.importedStyleJSON.styleDefinitions;
      this.updateStyle();
    },
    async treeNodeClicked(node) {
      console.log('Style Editor node clicked: ', node);
      this.selectedNode = {};
      await this.$nextTick();
      if(node) {
        this.selectedNode = node;
      }
    },
    collapseTree() {
      this.$refs.treeCtrl.collapseAllNodes();
    },
    expandTree() {
      this.$refs.treeCtrl.expandAllNodes();
    },
    async fetchLayer() {
      this.layer = await this.$store.dispatch('apiGetData', {
        url: '/projects/' + this.projectID + '/layers/' + this.layerID,
        commit: false,
      });
      console.log('Fetched layer: ', this.layer.ID);
    },

    async updateStyle() {
      console.log('SE: updateStyle called: ', this.selectedNode);
      console.log("Layer.StyleDefinitions: " , this.layer.StyleDefinitions)
      // Do actual work to push new updates to the server here.

      let urlToHit = '/projects/' + this.projectID + '/layers/' + this.layerID;

      try {
        await this.$store.dispatch('apiPutData', {
          url: urlToHit,
          commit: false,
          body: this.layer,
        });
      } catch (err) {
        if (~err.message.search('401')) {
          err.message += ' Unauthorized';
        }
        Helpers.showAlert(err);
      } finally {
        this.getProjectName(); // force reload of projects to store to get latest layers for prjs
      }
    },
    insertNodeAbove(index, node, obj) {
      // fires when the node rt. clicked on is a top most level node
      // Do insertion work here.
      console.log("insertAbove: ", index, node);

      if (obj && typeof obj === 'object' && obj.constructor === Object){
        this.treeData.splice(index, 0, obj);
        this.$emit('tlctr-node-inserted');
        this.updateStyleWithInsert();
      }
    },
    async updateStyleWithInsert() {
      let urlToHit = '/projects/' + this.projectID + '/layers/' + this.layerID;
      this.$refs.treeCtrl.handleNodeClick({}); // select nothing, i.e. unselect any selection
      try {
        await this.$store.dispatch('apiPutData', {
          url: urlToHit,
          commit: false,
          body: this.layer,
        });
        await this.$nextTick();
        await this.fetchLayer(); // force reload of layer
      } catch (err) {
        if (~err.message.search('401')) {
          err.message += ' Unauthorized';
        }
        Helpers.showAlert(err);
      }
    },
    updateNode(newNode) {
      if(newNode) {
        this.selectedNode = newNode;
      }

      console.log('SE: updateNode called', this.selectedNode);

      this.updateStyle();
    },
    async getProjectName() {
      await this.$store.dispatch('apiGetData', {
        url: '/projects',
        commit: true,
        stateProperty: 'projects',
        responseProperty: 'Items',
        statePropertyLoaderToToggle: 'loadingProjects',
      });

      let projects = this.$store.getters.getProjects;
      for (let i = 0; i < projects.length; i++) {
        if (this.projectID == projects[i].ID) {
          this.localProjectName = projects[i].Name;
        }
      }
    },
    toggleImportModal() {
      const files = this.$refs.styleImport.files;
      if (files.length <= 0) {
        return false;
      }

      const fr = new FileReader();

      fr.onload = e => {
        this.importedStyleJSON = JSON.parse(e.target.result);
      };
      fr.readAsText(files.item(0));
      this.$bvModal.show('styleImportModal');
    },
    createStyleSelectorMsgBox(parentNode) {
      const h = this.$createElement;

      if(parentNode &&
      (
        parentNode.Type == "Point" ||
        parentNode.Type == "None" ||
        parentNode.Type == "Data Grid" ||
        parentNode.Type == "Surface Object"
      )) {
                const messageVNode = h('div', { class: ['modal-complete'] }, [
                h('div', {
                  domProps: {
                    innerHTML:  `
                                <h2>You can not add a new Style at this location.</h2>

                            `
                  }
                })
              ])
            return messageVNode;
      }

      const messageVNode = h('div', { class: ['modal-complete'] }, [
        h('div', {
          domProps: {
            innerHTML:  `
                        <h2>Select Component Type</h2>
                        <select id="styleSelectionID">
                          /* <option disabled value="">Please select one</option> */
                          <option selected>Group</option>
                          <option>Transform</option>
                          <option>Color</option>
                          <option>ColorMap</option>
                          <option>CustomText</option>
                          <option>LineString</option>
                          <option>Label</option>
                          <option>Sprite</option>
                          <option>Shape</option>
                          <option>Datagrid</option>
                          <option>Point</option>
                          <option>SurfaceObject</option>
                          <option>InitialPointShape</option>
                          <option>TerminalPointShape</option>
                          <option>MaxVisibleDistance</option>
                          <option>FieldText</option>
                          <option>OwnerText</option>
                          <option>MinScaleDistance</option>
                        </select>
                    `
          }
        })
      ])

      return messageVNode;
    },
    handleBeforeInsert(parentNode) {
      console.log("StyleEditor::handleBeforeInsert")

      return new Promise((resolve, reject) => {
        let boxContents = this.createStyleSelectorMsgBox(parentNode);
        this.$bvModal
          .msgBoxConfirm([boxContents])
          // .show('insertStyleDialog')
          .then((value) => {
            if(value) {
              let el = document.getElementById('styleSelectionID');
              let styleType = el.options[el.selectedIndex].value;
              resolve({Type: styleType})
            } else {
              reject(false);
            }
          })
          .catch(() => {
            reject(false);
          })

        // resolve({Type: "Group"}) || reject(false);
      });
    },
    handleBeforeDelete() {
      return this.$bvModal  // returns a promise
        .msgBoxConfirm("Are you sure?  This action can not be undone.")
        .then(value => {
          console.log("DELETE CONFIRMATION VALUE: ", value);
          return value;
        })
        .catch(err => {
          // An error occurred
          console.log(err);
          return false;
        });
    }
  },
  mounted() {
    this.$refs.treeCtrl.setSecondLabelDataProperty('DisplayName');
  },
  created() {
    Helpers.doViewCreation(this);
    this.layer = this.layerObj; // keep local copy of prp
    console.log('StyleEditor: ', this.projectID, ' ;; ', this.layerID);
    if (
      Object.keys(this.layer).length === 0 &&
      this.layer.constructor === Object
    ) {
      this.fetchLayer();
    }

    if (this.projectName == '') {
      this.getProjectName();
    } else {
      this.localProjectName = this.projectName;
    }

    this.$store.dispatch('apiGetData', {
      url: '/sprites',
      commit: true,
      stateProperty: 'sprites',
      responseProperty: 'Items',
      statePropertyLoaderToToggle: 'loadingSprites',
    });
    this.$store.dispatch('apiGetData', {
      url: '/shapes',
      commit: true,
      stateProperty: 'shapes',
      responseProperty: 'Items',
      statePropertyLoaderToToggle: 'loadingShapes',
    });
  },
  components: {
    TreeListCtrl,
    StyleEdit,
  },
};
</script>
