<template>
  <span>
    <!-- can set :value="color" display= widget or picker for initial start value color -->
    <verte
      class=""
      v-model="color"
      display="picker"
      model="rgb"
      menuPosition="bottom"
      :showHistory="true"
      :rgbSliders="true"
      @submit="updateColor"
      @close="resetPicker"
    >
      <div
        style="position:relative; left:0px; width: 100px; height: 30px;"
        :style="{
          'background-color': color,
          position: 'relative',
          left: '0px',
          width: '150px',
          height: '30px',
        }"
        class="border"
      >
        <span class="f7 black">click me</span>
      </div>
    </verte>
  </span>
</template>

<script>
export default {
  name: 'StyleEditColorPicker',
  props: {
    initialColor: Object,
  },
  data() {
    return {
      resetColor: 'rgba(255,255,255, 0.5)', // color to go back to if user cancels color picker
      color: 'rgba(255,255,255, 0.5)',  // bound color from picker
    };
  },
  methods: {
    resetPicker(/* c */) {
      this.color = this.resetColor;
    },
    updateColor(c) {
      console.log('updateColor: ', c);
      // new color in c, in format rgba(255,255,255, 0.5)
      let match = c.match(
        /^rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,*\s*(\d*(?:\.\d+)*)*\)/i,
      );
      let newColor = this.initialColor;
      newColor.R = match[1] / 255;
      newColor.G = match[2] / 255;
      newColor.B = match[3] / 255;
      if (match[4]) {
        newColor.A = match[4];
      } else {
        newColor.A = 1;
      }
      console.log('newNode: ', newColor);
      this.resetColor = this.color; // new color value to reset to if user cancels picker dialog
      this.$emit('value-changed', newColor);
    },
  },
  mounted() {
    this.resetColor = this.color =
      'rgba(' +
      Math.round(this.initialColor.R * 255) +
      ',' +
      Math.round(this.initialColor.G * 255) +
      ',' +
      Math.round(this.initialColor.B * 255) +
      ',' +
      Math.round(this.initialColor.A * 255) +
      ')';

  },
  created() {},
};
</script>
