<template>
  <div>
    <div class="ml2">

      <div class="ml0">
        <style-edit-boolean
          label="Enabled"
          v-model="localSurface.Enabled"
          @value-changed="updateStyle"
        />

        <style-edit-field-label label="Layer Type" />
        <select
          v-model="localSurface.SurfaceLayerType"
          class="w5 f5"
          @change="updateStyle"
        >
          <option value="NativeImagery">Native Imagery</option>
          <option value="FixedColor">Fixed Color</option>
          <option value="Elevation">Elevation</option>
          <option value="Imagery">External Imagery</option>
          <option value="None">None</option>
        </select>
        <hr />
        <div v-if="surface.SurfaceLayerType == 'FixedColor'">
          <style-edit-field-label
            label="Fixed Color"
            v-model="localSurface.FixedColor"
          />

          <style-edit-color-picker
            :initialColor="surface.FixedColor"
            @value-changed="processFixedColor"
          />
          <hr />
        </div>
        <div v-if="surface.SurfaceLayerType == 'Imagery'">
          <style-edit-field-label label="External Imagery Source" />
          <select
            v-model="localSurface.TerrainImageryType"
            class="w5 f5"
            @change="updateStyle"
          >
            <option value="BingAerial">Bing Aerial</option>
            <option value="BingStreets">Bing Streets</option>
            <option value="DigitalGlobeAerial">Digital Globe Aerial</option>
            <option value="DigitalGlobeStreets">Digital Globe Streets</option>
          </select>
          <hr />
        </div>
        <div v-if="surface.SurfaceLayerType == 'Elevation'">
          <style-edit-color-map-component
            :colorMap="surface.ColorMap"
            @value-changed="updateStyle"
          />
          <hr />
        </div>
        <div class="f6 mt4">
          <hr />
          <pre>{{ surface }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StyleEditFieldLabel from '@/components/StyleEditor/StyleEditFieldLabel.vue';
import StyleEditBoolean from '@/components/StyleEditor/StyleEditBoolean.vue';
import StyleEditColorPicker from '@/components/StyleEditor/StyleEditColorPicker.vue';
import StyleEditColorMapComponent from '@/components/StyleEditor/StyleEditColorMapComponent.vue';

export default {
  name: 'StyleEditSurfaceObject',
  props: {
    surface: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localSurface: this.surface,
    };
  },
  methods: {
    updateStyle() {
      console.log('StyleEditSurfaceObject, valueChange: ', this.localSurface);
      this.$emit('value-changed', this.localSurface);
    },
    processColor(index, event) {
      console.log(event);
      let newSurface = this.localSurface;
      newSurface.ColorMap[index].Color.A = event.A;
      newSurface.ColorMap[index].Color.R = event.R;
      newSurface.ColorMap[index].Color.G = event.G;
      newSurface.ColorMap[index].Color.B = event.B;
      console.log('newSurface: ', newSurface);
      this.$emit('value-changed', newSurface);
    },
    processFixedColor(event) {
      let newSurface = this.localSurface;
      newSurface.FixedColor.A = event.A;
      newSurface.FixedColor.R = event.R;
      newSurface.FixedColor.G = event.G;
      newSurface.FixedColor.B = event.B;
      console.log('newSurface: ', newSurface);
      this.$emit('value-changed', newSurface);
    },
  },
  components: {
    StyleEditFieldLabel,
    StyleEditBoolean,
    StyleEditColorPicker,
    StyleEditColorMapComponent,
  },
};
</script>
