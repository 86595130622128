<template>
  <div id="addlayer" class="container-fluid">
    <h1 class="page-header primary">Add Layer
    </h1>

    <label for="name" class="f6 b mb2 mr2 fl w-10"
      >Name <span class="normal black-60">(required)</span></label
    >
    <input
      class="input-reset ba b--black-20 pa2 mb2 mr2 w-15"
      type="text"
      name="name"
      v-model="input.name"
      placeholder=""
      autofocus
    />
    <p class="pa1"></p>

    <label for="description" class="f6 b mb2 mr2 fl w-10"
      >Description <span class="normal black-60">(optional)</span></label
    >
    <textarea
      class="input-reset ba b--black-20 pa2 mb2 mr2 w-15"
      type="textarea"
      rows="2"
      name="description"
      v-model="input.description"
      placeholder=""
    />

    <p class="pa1"></p>

    <label for="description" class="f6 b mb2 mr2 fl w-10"
      >Channel <span class="normal black-60">(required)</span></label
    >
    <select v-model="input.channelID">
      <option disabled value="">Please select one</option>
      <option v-for="channel in channels" v-bind:key="channel.ID" :value="channel.ID">{{channel.Name}} ({{channel.ID}})</option>
    </select>

    <p class="pa1"></p>

    <button
      class="f6 dim primary b bg-white center ph3 pv2 mb2 br3"
      type="button"
      v-on:click="createLayer()"
    >
      Add Layer
    </button>
  </div>
</template>

<script>
import Helpers from '@/helpers.js';
import { mapGetters } from 'vuex';

export default {
  name: 'AddLayer',
  data() {
    return {
      input: {
        name: '',
        description: '',
        channelID: ''
      },
    };
  },
  computed: {
    ...mapGetters(['getChannels', 'getUsers', 'getSessionObj']),
    channels() {
      let myChannels = this.getChannels;
      myChannels.forEach(c => {
        let user = this.getUsers.find(u => u.ID === c.OwnerID);
        if (user) {
          c.OwnerName = user.Name;
        }
      });
      return myChannels;
    },
  },
  methods: {
    createLayer() {
      if (this.input.name == '') {
        console.log('A Layer name must be present');
        Helpers.showAlert('You must enter a layer name');
        return;
      }

      if (this.input.channelID == '') {
        console.log('A ChannelID must be selected');
        Helpers.showAlert('You must select a channel');
        return;
      }

      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      let url = this.getSessionObj.apiURL;
      let requestBody = [{
        'Name': this.input.name,
        'Description': this.input.description,
        'ChannelID': this.input.channelID,
        'ProjectID': this.$route.params.id
      }]
      console.log(requestBody)
      this.$http
        .post(url + '/projects/' + this.$route.params.id + '/layers', requestBody, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function(response) {
            console.log('Success posting to /projects/:projectID/layers : ', response.body);
            // this.channels.push(JSON.parse(response.bodyText)[0])
            this.$router.push({
              path: '/projects/' + this.$route.params.id,
              query: {
                alert:
                  'Success! Added ' +
                  response.body[0].Name +
                  ' with ID: ' +
                  response.body[0].ID,
              },
            });
          },
          response => {
            // Fail
            console.log('Failed POST to /projects/:projectID/layers :', response.body);
            if (response.body) {
              this.alert = 'Error: ' + response.body;
              Helpers.showAlert('Error: ${response.body}');
            } else {
              Helpers.showAlert('Failed to post to /projects/:projectID/layers');
            }
          }
        );
    },
  },
  created: function() {
    Helpers.doViewCreation(this);
  },
  components: {},
};
</script>

<style scoped></style>
