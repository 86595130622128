<template>
  <div>
    <div class="ml2">
      <div class="ml0">
        <style-edit-boolean
          label="Enabled"
          v-model="localPoint.Enabled"
          @value-changed="updateStyle"
        />
        <style-edit-boolean
          label="Use Lighting"
          v-model="localPoint.UseLighting"
          @value-changed="updateStyle"
        />

        <style-edit-field-label
          label="Size"
          v-model="localPoint.Size"
          @value-changed="updateStyle"
        />
        <input
          v-model.number="localPoint.Size"
          @change="updateStyle"
          class="w3 f5"
        />
        <hr />
        <style-edit-field-label
          label="Size Mode"
          v-model="localPoint.SizeMode"
          @value-changed="updateStyle"
        />
        <select v-model="localPoint.SizeMode">
          <option value="ConstantWorldSize">Constant World Size</option>
          <option value="ConstantScreenSize">Constant Screen</option>
        </select>
        <hr />
        <style-edit-field-label
          label="Value Source"
          v-model="localPoint.ValueSource"
          @value-changed="updateStyle"
        />
        <select v-model="localPoint.ValueSource">
          <option value="TextureProperty">Properties</option>
          <option value="CameraDistance">Distance From Camera</option>
        </select>
        <hr />
        <style-edit-field-label label="Color Values" />
        <style-edit-color-map-component
          :colorMap="localPoint.ColorMap"
          @value-changed="updateStyle"
        />
        <div class="f6 mt4">
          <hr />
          <pre>{{ localPoint }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StyleEditFieldLabel from '@/components/StyleEditor/StyleEditFieldLabel.vue';
import StyleEditBoolean from '@/components/StyleEditor/StyleEditBoolean.vue';
import StyleEditColorMapComponent from '@/components/StyleEditor/StyleEditColorMapComponent.vue';

export default {
  name: 'StyleEditPoint',
  props: {
    point: {
      type: Object,
      required: true,
    },
  },
  watch: {
    point() {
      this.localPoint = this.point;
    }
  },
  data() {
    return {
      localPoint: this.point,
    };
  },
  methods: {
    updateStyle() {
      console.log('StyleEditPoint, valueChange: ', this.localPoint);
      this.$emit('value-changed', this.localPoint);
    },
    processColor(index, event) {
      console.log(event);
      let newPoint = this.point;
      newPoint.ColorMap[index].Color.A = event.A;
      newPoint.ColorMap[index].Color.R = event.R;
      newPoint.ColorMap[index].Color.G = event.G;
      newPoint.ColorMap[index].Color.B = event.B;
      console.log('newPoint: ', newPoint);
      this.$emit('value-changed', newPoint);
    },
  },
  components: {
    StyleEditFieldLabel,
    StyleEditBoolean,
    StyleEditColorMapComponent,
  },
};
</script>
