<template>
  <div id="addchannel" class="container-fluid">
    <h1 class="page-header primary">Add Channel</h1>

    <label for="name" class="f6 b mb2 mr2 fl w-10"
      >Name <span class="normal black-60">(required)</span></label
    >
    <input
      class="input-reset ba b--black-20 pa2 mb2 mr2 w-15"
      type="text"
      name="name"
      v-model="input.name"
      placeholder=""
      autofocus
    />
    <p class="pa1"></p>

    <label for="description" class="f6 b mb2 mr2 fl w-10"
      >Description <span class="normal black-60">(optional)</span></label
    >
    <textarea
      class="input-reset ba b--black-20 pa2 mb2 mr2 w-15"
      type="textarea"
      rows="2"
      name="description"
      v-model="input.description"
      placeholder=""
    />

    <p class="pa1"></p>

    <button
      class="f6 dim primary b bg-white center ph3 pv2 mb2 br3"
      type="button"
      v-on:click="createChannel()"
    >
      Add Channel
    </button>
  </div>
</template>

<script>
import Helpers from '@/helpers.js';
import { mapGetters } from 'vuex';

export default {
  name: 'AddChannel',
  data() {
    return {
      input: {
        name: '',
        description: '',
      },
    };
  },
  computed: mapGetters(['getSessionObj']),
  methods: {
    createChannel() {
      if (this.input.name == '') {
        console.log('A channel name must be present');
        Helpers.showAlert('You must enter a chanenl name');
        return;
      }

      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(Helpers.errorMsgTokenExpired());
        return;
      }

      let url = this.getSessionObj.apiURL;
      let requestBody = [{
        'Name': this.input.name,
        'Description': this.input.description
      }]
      this.$http
        .post(url + '/channels', requestBody, {
          headers: { authorization: this.getSessionObj.access_token },
        })
        .then(
          function(response) {
            console.log('Success posting to /channels: ', response.body);
            // this.channels.push(JSON.parse(response.bodyText)[0])
            this.$router.push({
              path: '/channels',
              query: {
                alert:
                  'Success! Added ' +
                  response.body[0].Name +
                  ' with ID: ' +
                  response.body[0].ID,
              },
            });
          },
          response => {
            // Fail
            console.log('Failed POST to /channels', response.body);
            if (response.body) {
              this.alert = 'Error: ' + response.body;
              Helpers.showAlert('Error: ${response.body}');
            } else {
              Helpers.showAlert('Failed to post to /channels');
            }
          }
        );
    },
  },
  created: function() {
    Helpers.doViewCreation(this);
  },
  components: {},
};
</script>

<style scoped></style>
