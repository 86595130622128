<template>
  <div>
    <div class="ml2">
      <div class="ml0">
        <style-edit-boolean
          label="Enabled"
          v-model="localColorMap.Enabled"
          @value-changed="updateStyle"
        />
        <div>
          <style-edit-field-label label="Field" />
          <input
            v-model.number="localColorMap.Field"
            @change="updateStyle"
            class="w8 f5"
          />
        </div>
        <style-edit-color-map-component
          :colorMap="localColorMap.ColorMap"
          @value-changed="updateStyle"
        />

        <div class="f6 mt4">
          <hr />
          <pre>{{ localColorMap }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StyleEditFieldLabel from '@/components/StyleEditor/StyleEditFieldLabel.vue';
import StyleEditBoolean from '@/components/StyleEditor/StyleEditBoolean.vue';
import StyleEditColorMapComponent from '@/components/StyleEditor/StyleEditColorMapComponent.vue';

export default {
  name: 'StyleEditColorMap',
  props: {
    colorMap: {
      type: Object,
      required: true,
    },
  },
  watch: {
    colorMap() {
      this.localColorMap = this.colorMap;
    }
  },
  data() {
    return {
      localColorMap: this.colorMap,
    };
  },
  methods: {
    updateStyle() {
      console.log('StyleEditColorMap, valueChange: ', this.localColorMap);
      this.$emit('value-changed', this.localColorMap);
    },
    processColor(index, event) {
      console.log(event);
      let newColorMap = this.colorMap;
      newColorMap.ColorMap[index].Color.A = event.A;
      newColorMap.ColorMap[index].Color.R = event.R;
      newColorMap.ColorMap[index].Color.G = event.G;
      newColorMap.ColorMap[index].Color.B = event.B;
      console.log('newColorMap: ', newColorMap);
      this.$emit('value-changed', newColorMap);
    },
  },
  components: {
    StyleEditFieldLabel,
    StyleEditBoolean,
    StyleEditColorMapComponent,
  },
};
</script>
