<template>
  <div class="ml2">

    <div class="ml0">
      <style-edit-boolean
        label="Enabled"
        v-model="localText.Enabled"
        @value-changed="updateStyle"
      />
      <div>
        <style-edit-field-label label="Custom Text" />
        <input v-model.number="localText.Text" @change="updateStyle" class="w3 f5" />
      </div>
    </div>
    <span class="f5"
      ><pre>{{ localText }}</pre></span
    >
  </div>
</template>

<script>
import StyleEditFieldLabel from '@/components/StyleEditor/StyleEditFieldLabel.vue';
import StyleEditBoolean from '@/components/StyleEditor/StyleEditBoolean.vue';
export default {
  name: 'StyleEditCustomText',
  props: {
    text: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localText: this.text,
    };
  },
  watch: {
    localText: function(newVal, oldVal) {
      console.log('Edited localLabel (new, old): ', newVal, oldVal);
    },
    text() {
      this.localText = this.text;
    }
  },
  created() {},
  methods: {
    updateStyle() {
      console.log('StyleEditTransform, valueChange: ', this.localText);
      this.$emit('value-changed', this.localText);
    },
  },
  components: {
    StyleEditFieldLabel,
    StyleEditBoolean,
  },
};
</script>
