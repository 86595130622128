<template>
  <div class="ml2">


    <div class="ml0">
      <style-edit-boolean
        label="Enabled"
        v-model="localShape.Enabled"
        @value-changed="updateStyle"
      />

      <style-edit-field-label label="Shape" />
      <select v-if="this.$store.getters.getLoadingShapes" class="w5 f5">
        <option disabled>Loading</option>
      </select>
      <select
        v-if="!this.$store.getters.getLoadingShapes"
        v-model="localShape.ShapeID"
        class="w5 f5"
        @change="updateStyle"
      >
        <option disabled :value="null">Please select one</option>
        <option
          v-for="sh in shapeList"
          v-bind:key="sh.ID"
          :value="sh.ID"
          >{{ sh.Name }} ({{ sh.ID }})</option
        >
      </select>
      <hr />

      <pre class="f6">{{ localShape }}</pre>
    </div>
  </div>
</template>

<script>
import StyleEditFieldLabel from '@/components/StyleEditor/StyleEditFieldLabel.vue';
import StyleEditBoolean from '@/components/StyleEditor/StyleEditBoolean.vue';
export default {
  name: 'StyleEditTerminalPointShape',
  props: {
    shape: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localShape: this.shape,
    };
  },
  watch: {
    localShape: function(newVal, oldVal) {
      console.log('Edited localShape (new, old): ', newVal, oldVal);
    },
    shape() {
      this.localShape = this.shape;
    }
  },
  computed: {
    shapeList() {
      return this.$store.getters.getShapes;
    },
  },
  created() {},
  methods: {
    updateStyle() {
      console.log('StyleEditTerminalPointShape, valueChange: ', this.localShape);
      this.$emit('value-changed', this.localShape);
    },
  },
  components: {
    StyleEditFieldLabel,
    StyleEditBoolean,
  },
};
</script>
