<template>
  <div>
    !! Editing not yet implemented !!
    <!-- Add DisplayName if exists, Enabled, and Rules -->
    <pre class="f6">{{ group }}</pre>
  </div>
</template>

<script>
export default {
  name: 'StyleEditGroup',
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
