<template>
  <div>

    <div class="ml2">
      <!-- <div class="f4">Current color:</div>
      <span class="f7 dib v-mid ml4">{{ color }}</span>
      <div
        class="tc w4  h2 border f7 ml4"
        :style="{ 'background-color': color }"
      ></div> -->
      <div class="f4">
        Click below to select new color:<br />

        <!-- can set :value="color" display= widget or picker for initial start value color -->
        <style-edit-color-picker
          :initialColor="this.node.Color"
          @value-changed="processColor"
        />
      </div>
    </div>
    <div class="f6 mt4">
      <hr />
      <pre>{{ node }}</pre>
    </div>
  </div>
</template>

<script>
import StyleEditColorPicker from '@/components/StyleEditor/StyleEditColorPicker.vue';

export default {
  name: 'StyleEditColor',
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      color: 'rgba(255,255,255, 0.5)',
    };
  },
  methods: {
    processColor(event) {
      console.log(event);
      let newNode = this.node;
      newNode.Color.A = event.A;
      newNode.Color.R = event.R;
      newNode.Color.G = event.G;
      newNode.Color.B = event.B;
      console.log('newNode: ', newNode);
      this.$emit('value-changed', newNode);
    },
  },
  mounted() {},
  created() {},
  components: {
    StyleEditColorPicker,
  },
};
</script>
