<template>
  <div>
    <div class="ml2">
      <div class="ml0">
        <style-edit-boolean
          label="Enabled"
          v-model="localDatagrid.Enabled"
          @value-changed="updateStyle"
        />
        <style-edit-boolean
          label="Use Range Colors"
          v-model="localDatagrid.UseRangeColorMap"
          @value-changed="updateStyle"
        />
        <style-edit-color-map-component
          :colorMap="localDatagrid.ColorMap"
          @value-changed="updateStyle"
        />
        <div class="f6 mt4">
          <hr />
          <pre>{{ localDatagrid }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StyleEditBoolean from '@/components/StyleEditor/StyleEditBoolean.vue';
import StyleEditColorMapComponent from '@/components/StyleEditor/StyleEditColorMapComponent.vue';

export default {
  name: 'StyleEditDataGrid',
  props: {
    datagrid: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localDatagrid: this.datagrid,
    };
  },
  methods: {
    updateStyle() {
      console.log('StyleEditDataGrid, valueChange: ', this.localDatagrid);
      this.$emit('value-changed', this.localDatagrid);
    },
  },
  components: {
    StyleEditBoolean,
    StyleEditColorMapComponent,
  },
};
</script>
