<template>
  <div class="ml2">

    <div class="ml0">
      <style-edit-boolean
        label="Enabled"
        v-model="localTransform.Enabled"
        @value-changed="updateStyle"
      />

      <style-edit-boolean
        label="SetPivotToBottom"
        v-model="localTransform.SetPivotToBottom"
        @value-changed="updateStyle"
      />

      <style-edit-field-label label="Translation:" class="" />
      <div>
        <style-edit-field-label label="x" class="ml4" />
        <input
          v-model.number="localTransform.Translation.X"
          @change="updateStyle()"
          class="w3 f5"
        />
        <style-edit-field-label label="y" class="ml4" />
        <input
          v-model.number="localTransform.Translation.Y"
          @change="updateStyle()"
          class="w3 f5"
        />
        <style-edit-field-label label="z" class="ml4" />
        <input
          v-model.number="localTransform.Translation.Z"
          @change="updateStyle()"
          class="w3 f5"
        />
      </div>

      <style-edit-field-label label="Rotation:" class="" />
      <div>
        <style-edit-field-label label="x" class="ml4" />
        <input
          v-model.number="localTransform.Rotation.X"
          @change="updateStyle"
          class="w3 f5"
        />
        <style-edit-field-label label="y" class="ml4" />
        <input
          v-model.number="localTransform.Rotation.Y"
          @change="updateStyle"
          class="w3 f5"
        />
        <style-edit-field-label label="z" class="ml4" />
        <input
          v-model.number="localTransform.Rotation.Z"
          @change="updateStyle"
          class="w3 f5"
        />
      </div>

      <style-edit-field-label label="Scale:" class="" />
      <div>
        <style-edit-field-label
          v-if="!localTransform.IsUniformScale"
          label="x"
          class="ml4"
        />
        <input
          v-model.number="localTransform.Scale.X"
          @change="updateStyle"
          class="w3 f5"
        />
        <style-edit-field-label
          v-if="!localTransform.IsUniformScale"
          label="y"
          class="ml4"
        />
        <input
          v-model.number="localTransform.Scale.Y"
          @change="updateStyle"
          v-if="!localTransform.IsUniformScale"
          class="w3 f5"
        />
        <style-edit-field-label
          v-if="!localTransform.IsUniformScale"
          label="z"
          class="ml4"
        />
        <input
          v-model.number="localTransform.Scale.Z"
          @change="updateStyle"
          v-if="!localTransform.IsUniformScale"
          class="w3 f5"
        />
      </div>

      <style-edit-boolean
        label="IsUniformScale"
        v-model="localTransform.IsUniformScale"
        @value-changed="updateStyle"
      />

      <hr />

      <pre class="f6">{{ localTransform }}</pre>
    </div>
  </div>
</template>

<script>
import StyleEditFieldLabel from '@/components/StyleEditor/StyleEditFieldLabel.vue';
import StyleEditBoolean from '@/components/StyleEditor/StyleEditBoolean.vue';
export default {
  name: 'StyleEditTransform',
  props: {
    transform: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localTransform: {},
    };
  },
  watch: {
    localTransform: function(newVal, oldVal) {
      console.log('Edited localTransform (new, old): ', newVal, oldVal);
    },
  },
  created() {
    this.localTransform = this.transform;
  },
  methods: {
    updateStyle() {
      if (this.localTransform.IsUniformScale) {
        this.localTransform.Scale.Y = this.localTransform.Scale.X;
        this.localTransform.Scale.Z = this.localTransform.Scale.X;
      }
      console.log('StyleEditTransform, valueChange: ', this.localTransform);
      this.$emit('value-changed', this.localTransform);
    },
  },
  components: {
    StyleEditFieldLabel,
    StyleEditBoolean,
  },
};
</script>
