<template>
  <div>
    <div class="ml2">
      <div class="ml0">
        <style-edit-boolean
          label="Enabled"
          v-model="localMaxDistance.Enabled"
          @value-changed="updateStyle"
        />
        <div>
          <style-edit-field-label
            label="Max Visible Distance (m)"
            v-model="localMaxDistance.MaxVisibleDistanceM"
            @value-changed="updateStyle"
          />
          <input
            v-model.number="localMaxDistance.MaxVisibleDistanceM"
            @change="updateStyle"
            class="w3 f5"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StyleEditFieldLabel from '@/components/StyleEditor/StyleEditFieldLabel.vue';
import StyleEditBoolean from '@/components/StyleEditor/StyleEditBoolean.vue';

export default {
  name: 'StyleEditMaxVisibleDistance',
  props: {
    maxDistance: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localMaxDistance: this.maxDistance,
    };
  },
  methods: {
    updateStyle() {
      console.log('StyleEditMaxDistance, valueChange: ', this.localMaxDistance);
      this.$emit('value-changed', this.localMaxDistance);
    },
  },
  components: {
    StyleEditFieldLabel,
    StyleEditBoolean,
  },
};
</script>
