<template>
  <div>
    <div v-for="(node, idx) in treeData" :key="node[uniqueIDDataProperty] ? node[uniqueIDDataProperty] : idx">
      <tree-list-ctrl-node
        :node="node"
        :index="idx"
        :childrenDataProperty="childrenDataProperty"
        :labelDataProperty="labelDataProperty"
        :secondLabelDataProperty="localOptions.secondLabelDataProperty"
        :handleNodeClick="handleNodeClick"
        :handleBeforeDelete="handleParentBeforeDelete"
        :handleBeforeInsert="handleParentBeforeInsert"
        :selectedNode="theSelectedNode"
        :uniqueIDDataProperty="uniqueIDDataProperty"
        :indentForEachTreeLevel_Pixels="
          localOptions.indentindentForEachTreeLevel_Pixels
        "
        :expandNodes="localOptions.expandNodes"
        :toggleNodeReset="localOptions.expandNodes"
        :truncateLabelAtChars="localOptions.truncateLabelAtChars"
        @tlctr-node-shift-up="shiftNodeUp"
        @tlctr-node-shift-down="shiftNodeDown"
        @tlctr-node-order-changed="nodeOrderChanged"
        @tlctr-node-inserted="$emit('tlctr-node-inserted')"
        @tlctr-node-insert-child="insertChild"
        @tlctr-node-delete="deleteNode"
        @tlctr-node-insert-above="insertAbove"
        @tlctr-select-new-node="selectNewNode"
      >
        <template #iconParent>
          <i class="pl2 f4 gray fa fa-caret-right mr1" />
        </template>
        <template #iconParentExpanded>
          <i class="pl2 f4 gray fa fa-caret-down mr1" />
        </template>
        <template #iconChild>
          <i class="pl2 f7 gray fa fa-circle mr1" />
        </template>
        <template #iconShiftNodeUp>
          <i class="fa fa-arrow-up f6 gray mr1" />
        </template>
        <template #iconShiftNodeDown>
          <i class="fa fa-arrow-down f6 gray" />
        </template>
      </tree-list-ctrl-node>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Helpers from "@/helpers.js";
// import ClickToEdit from '@/components/ClickToEdit.vue';
import TreeListCtrlNode from '@/components/TreeListCtrlNode.vue';
import Vue from 'vue';

// Create a tree control specific message bus for passing messages back and forth to
export const treeListCtrlMessageBus = new Vue();

export default {
  name: 'TreeListCtrl',
  props: {
    treeData: null,
    labelDataProperty: {
      type: String,
      required: true,
    },
    childrenDataProperty: {
      type: String,
      required: true,
    },
    uniqueIDDataProperty: {
      type: String,
      required: false,
      default: 'id',
    },
    handleBeforeDelete: {
      type: Function,
      required: false,
    },
    handleBeforeInsert: {
      type: Function,
      required: false,
      default: null,
    },
    options: {
      type: Object,
      required: false,
      default: () => {
        return {
          indentForEachTreeLevel_Pixels: 10,
          expandNodes: true,
          iconCssClassParent: '',
          iconCssClassParentExpanded: '',
          iconCssClassChild: '',
          secondLabelDataProperty: '',
          truncateLabelAtChars: 25, // max number of characters for a label
        };
      },
    }, // end options
  },
  data() {
    return {
      treeInstanceID: 0,
      theSelectedNode: {},
      redrawCounter: 0,
      localOptions: this.deepObjCopy(this.options),
      localTreeData: this.deepObjCopy(this.treeData),
    };
  },
  watch: {
    options() {
      Object.assign(this.localOptions, this.options);
    },
    treeData() {
      this.localTreeData = this.deepObjCopy(this.treeData);
    }
  },
  computed: {},
  methods: {
    async collapseAllNodes() {
      this.localOptions.expandNodes = true;
      console.log('selectedNode: ', this.theSelectedNode);
      await this.$nextTick();
      this.localOptions.expandNodes = false;
      console.log('selectedNode: ', this.theSelectedNode);
    },
    async expandAllNodes() {
      this.localOptions.expandNodes = false;
      let s = this.theSelectedNode;
      this.theSelectedNode = {};
      await this.$nextTick();
      this.localOptions.expandNodes = true;
      await this.$nextTick();
      this.theSelectedNode = s;
    },
    async setSecondLabelDataProperty(newValString) {
      this.localOptions.secondLabelDataProperty = '--';
      await this.$nextTick();
      this.localOptions.secondLabelDataProperty = newValString;
    },
    handleNodeClick(node) {
      console.log("TreeListCtrl handleNodeClick:", node);
      this.theSelectedNode = {};
      this.$nextTick(() => {
        this.theSelectedNode = node;
        this.$emit('tlctr-node-click', node);
      });
    },
    nodeOrderChanged() {
      console.log("TreeListCtrl nodeOrderChanged");
      let tempNode = this.theSelectedNode;
      this.theSelectedNode = {};
      this.$nextTick(() => {
        this.theSelectedNode = tempNode;
        this.$emit('tlctr-node-order-changed');
      });
    },
    shiftNodeUp(index) {
      console.log(index);
      if (index > 0) {
        let topNode = this.treeData[index];
        let bottomNode = this.treeData[index - 1];
        Vue.set(this.treeData, index - 1, topNode);
        Vue.set(this.treeData, index, bottomNode);
        this.nodeOrderChanged();
      }
    },
    shiftNodeDown(index) {
      console.log(index);
      if (index < this.treeData.length - 1) {
        let bottomNode = this.treeData[index];
        let topNode = this.treeData[index + 1];
        Vue.set(this.treeData, index + 1, bottomNode);
        Vue.set(this.treeData, index, topNode);
        this.nodeOrderChanged();
      }
    },
    handleParentBeforeInsert(parentNode) {
      if(this.handleBeforeInsert) {
        return this.handleBeforeInsert(parentNode); // can return a promise
      } else {
        let obj = {}; // resolve to default
        obj[this.labelDataProperty] = "Default"
        return Promise.resolve(obj);
      }
    },
    handleParentBeforeDelete() {
      if(this.handleBeforeDelete) {
        return this.handleBeforeDelete(); // can return a promise
      } else {
        return Promise.resolve(true); // no handler defined, so resolve to true
      }
    },
    insertAbove(index, node, obj) {
      // fires when the node rt. clicked on is a top most level node
      // Do insertion work here.
      console.log("insertAbove: ", index, node);
      this.$emit('tlctr-node-insert-above', index, node, obj);

    },
    insertChild() {
      this.nodeOrderChanged();
      this.$emit('tlctr-node-inserted');
    },
    deleteNode(index, node) {
      let newNodeToSelect = this.treeData[index + 1];
      if (!newNodeToSelect) {
        newNodeToSelect = this.treeData[index - 1];
      }

      Vue.delete(this.treeData, index);
      this.$emit('tlctr-node-order-changed');
      this.selectNewNode(newNodeToSelect);
      console.log("Deleted (index, node): ", index, node);
    },
    selectNewNode(node) {
      console.log("TreeListCtrl selectNewNode");
      this.theSelectedNode = {};
      this.$nextTick(() => {
        this.theSelectedNode = node;
        this.$emit('tlctr-node-click', node);
      });
    },
    deepObjCopy(inObject) {
      // see https://medium.com/javascript-in-plain-english/how-to-deep-copy-objects-and-arrays-in-javascript-7c911359b089
      let outObject, value, key

      if (typeof inObject !== "object" || inObject === null) {
        return inObject // Return the value if inObject is not an object
      }

      // Create an array or object to hold the values
      outObject = Array.isArray(inObject) ? [] : {}

      for (key in inObject) {
        value = inObject[key]

        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = this.deepObjCopy(value)
      }

      return outObject
    },
  },
  components: {
    TreeListCtrlNode,
  },
  mounted() {},
  created() {
    treeListCtrlMessageBus.$on('tlctr-select-new-node', this.selectNewNode)
  },
};
</script>

<style scoped>
.selectedColor {
  background: var(--my-primary-color);
}
</style>
