<template>
  <div>
    <div v-for="(colorValue, index) in colorMap" v-bind:key="index">
      <span>
        <div style="display:inline-block">
          <style-edit-color-picker
            :initialColor="colorValue.Color"
            @value-changed="processColor(index, $event)"
          />
        </div>
        <div style="display:inline-block; float: right" v-if="index != 0">
          <style-edit-field-label
            label="Value"
            v-model="colorValue.Value"
            @value-changed="updateStyle"
          />
          <input
            v-model.number="colorValue.Value"
            @change="updateStyle"
            class="w3 f5"
          />
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import StyleEditFieldLabel from '@/components/StyleEditor/StyleEditFieldLabel.vue';
import StyleEditColorPicker from '@/components/StyleEditor/StyleEditColorPicker.vue';

export default {
  name: 'StyleEditColorMapComponent',
  props: {
    colorMap: {
      type: Array,
      required: true,
    },
  },
  methods: {
    updateStyle() {
      console.log('StyleEditColorMap, valueChange: ', this.colorMap);
      this.$emit('value-changed', this.colorMap);
    },
    processColor(index, event) {
      console.log(event);
      let newColorMap = this.colorMap;
      newColorMap[index].Color.A = event.A;
      newColorMap[index].Color.R = event.R;
      newColorMap[index].Color.G = event.G;
      newColorMap[index].Color.B = event.B;
      console.log('newColorMap: ', newColorMap);
      this.$emit('value-changed', newColorMap);
    },
  },
  data() {
    return {};
  },
  components: {
    StyleEditFieldLabel,
    StyleEditColorPicker,
  },
};
</script>
